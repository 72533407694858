@import "../../../../theme/vendors/include-media";
.text {
  @include media('<desktop') {
    width: 100%;
    margin-bottom: 24px;
    padding: 0 8px;
  }
  margin-bottom: 40px;
  width: 383px;
  .message {
    font-size: 32px;
    color: #f0f00d;
    margin-bottom: 12px;
  }
  .title {
    font-size: 32px;
    color: #1f1e20;
    margin-bottom: 12px;

    @include media("<desktop") {
      font-size: 24px;
    }
  }
  .subtitle {
    font-size: 18px;
    color: #7e849b;

    @include media("<desktop") {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.box {
  width: 521px;
  padding: 32px 16px 32px 32px;
  margin-bottom: 12px;
  background: #ffffff;
  position: relative;
  box-shadow: 0px 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  @include media("<desktop") {
    width: 100%;
    padding: 20px 20px;
  }
  .box_title {
    font-size: 14px;
    color: #7e849b;
    margin-bottom: 16px;
    @include media("<desktop") {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
  .box_info {
    font-size: 20px;
    color: #1f1e20;
    margin: 0;
    @include media("<desktop") {
      font-weight: normal;
      font-size: 16px;
      line-height: 22px;
    }
  }
  .box_btn_edit {
    position: absolute;
    top: 16px;
    right: 16px;
    min-width: 40px;
  }
  .box_log_out {
    margin: 0;
    font-size: 20px;
    color: #7e849b;
    @include media("<desktop") {
      font-size: 18px;
    }
  }
}

.box_2 {
  flex-direction: row;
  align-items: center;

  .box_info {
    flex: 1;
  }
}

.box_btn {
  cursor: pointer;
}
