@import "../../base/variables";
@import "../../vendors/include-media";
.modal-dialog {
  padding: 32px;
  color: $gray;
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  max-width: 390px;
  min-width: 390px;

  &.modal-dialog-main {
    padding: 0;
    width: 100%;
    max-width: 488px;

    .MuiDialogContent-dividers {
      border: none;
      overflow: hidden;
    }

    .row-box {
      padding: 0 0 22px;

      form {
        width: 100%;
        margin: 0 0 20px;
      }
    }

    h3 {
      font-weight: 500;
      margin: 0 0 12px;
    }

    .date-time-box {
      display: flex;
      background: $white;
      position: relative;
      border: 1px solid $border-button-color;
      box-sizing: border-box;
      box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
      border-radius: 14px;
      padding: 24px;
      margin: 0 0 20px;

      .date-time-box-holder {
        padding: 0 14px 0 0;
        width: 50%;

        + .date-time-box-holder {
          padding: 0 0 0 14px;
        }
      }

      label {
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 0 0 12px;
      }
    }

    .add-break {
      background: $white;
      border: 1px solid $light-gray;
      box-sizing: border-box;
      box-shadow: 0 4px 20px rgba(122, 122, 122, 0.05);
      border-radius: 24px;
      font-size: 14px;
      line-height: 20px;
      font-weight: 500;
      color: $purple;
      padding: 14px 24px;
      padding-left: 44px;
      position: relative;
      display: table;
      margin: 0 auto;

      &:before,
      &:after {
        content: "";
        width: 13px;
        height: 1px;
        background: $purple;
        position: absolute;
        left: 24px;
        top: 22px;
      }

      &:after {
        top: 16px;
        left: 30px;
        width: 1px;
        height: 13px;
      }
    }
  }

  &.modal-dialog-medium {
    max-width: 454px;
    @include media("<desktop") {
      max-width: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0px !important;
      max-height: 100% !important;
      margin: 0 !important;
    }
  }

  &.modal-dialog-large {
    max-width: 846px;
  }

  &.text-center {
    text-align: center;
  }

  &.modal-dialog-service {
    max-width: 536px;

    @include media("<desktop") {
      height: 100%;
      max-height: 100%;
      max-width: 100%;
      margin: 0 !important;
    }

    .dialog-title {
      z-index: 1;
      position: relative;
      padding: 30px 57px 27px;
      box-shadow: 0 4px 20px rgba(122, 122, 122, 0.05);

      .Component-closeButton-9 {
        top: 16px;
      }

      @include media("<desktop") {
        padding: 20px 57px 20px;
      }
    }

    .btn-dialog-box {
      z-index: 1;
      position: relative;
      border-top: 1px solid $border-button-color;
      box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);
      display: flex;
      flex-direction: column;

      .btn-dialog-box-wrapper-btn {
        width: 100%;
        justify-content: space-between;

        button {
          margin: 0 4px;
        }
      }
    }

    .auto-scroll-box {
      padding-top: 16px;
    }
  }

  .MuiTypography-body1 {
    color: $gray;
  }

  .MuiTypography-h2 {
    font: 600 24px/28px $base-font-family;
    margin: 0 0 16px;
  }

  .MuiDialogTitle-root,
  .MuiDialogContent-root,
  .MuiDialogActions-root {
    padding: 0;
  }

  .MuiTypography-gutterBottom {
    margin-bottom: 20px;
  }

  .dialog-title {
    padding: 20px 57px 14px;
    text-align: center;

    h3 {
      margin: 0;
      font-weight: 500;
      @include media("<desktop") {
        font-weight: 500;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  .auto-scroll-box {
    max-height: calc(100vh - 288px);
    height: auto;
    overflow: hidden;
    overflow-y: auto;
    padding: 16px 24px;
    @include media("<=tablet") {
      padding: 20px;
      max-height: 100%;
    }

    @include media(">=tablet", "<desktop") {
      padding: 16px 80px;
      max-height: 100%;
    }
  }

  .btn-dialog-box {
    text-align: center;
    width: 100%;
    padding: 24px;

    &.footer-btn-alignment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 18px 32px;

      @include media("<=tablet") {
        padding: 18px 20px;
      }

      @include media(">=tablet", "<desktop") {
        padding: 18px 100px;
      }

      button {
        width: calc(50% - 10px);
        height: 48px;

        &[type="reset"] {
          height: auto;
        }
      }

      &.direction-columns {
        flex-direction: column;

        button {
          width: 100%;

          + button {
            margin-top: 15px;
          }
        }
      }

      &.btn-big {
        button {
          height: 56px;
          border: none;
          font-weight: 600;
          line-height: 20px;
          @include media("<desktop") {
            height: 45px;
          }
        }
      }

      &.big-btn-booking {
        button.white-btn {
          width: 100%;
        }
        button.white-btn-disable {
          opacity: 0.5;
        }
      }
    }

    &.btn-big--disable button {
      opacity: 0.5;
      pointer-events: none;
    }

    button {
      height: 52px;
      width: 100%;
    }
  }
  .back-button-wrapper {
    position: absolute;
    top: 12px;
    left: 8px;
    z-index: 2;
  }
}

.create-new-category {
  .MuiFormControl-root {
    margin: 0 0 24px;
  }
}

.text-error-reschedule {
  color: #e4597b;
  font-size: 14px;
  margin-top: 16px;
  margin-left: 0 !important;
  margin-bottom: 0;
}

.icon-loading-btn-modal {
  width: 24px !important;
  height: 24px !important;
  color: #ffff !important
}

.icon-loading-btn-second-modal {
  width: 24px !important;
  height: 24px !important;
  color: #5b13b7 !important
}
