@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';


.container_mobile_venue {
  width: 100% !important;
}

div.container {
  box-shadow: none;
  border-radius: 0 !important;
  background: none;
  font-weight: 400;
  font-size: 12px;
  color: $gray;
  cursor: pointer;
  max-width: 305px;

  @include media('<desktop') {
    max-width: 100%;
    width: 100%;
  }

  @include media(">=tablet", "<desktop") {
    max-width: 100%;
  }
  h2 {
    font-size: 20px;
  }
}

div.container_mobile {
  box-shadow: none;
  background: none;
  font-weight: 400;
  font-size: 12px;
  color: $gray;
  cursor: pointer;
  max-width: 100%;

  @include media('<desktop') {
    display: flex;
    background: #ffff;
    margin: 0px 4px;
    border-radius: 14px !important;
    height: 138px !important;
  }
  h2 {
    font-size: 20px;
  }
}
div.container_mobile_home {
  display: block;
  height: 100% !important;
  width: 100% !important;
  padding: 0px 8px !important;

  h2 {
    font-size: 20px;
  }
}

div.container_mobile_tablet {
  display: block;
  height: 100% !important;
  width: 50% !important;
  padding: 0px 8px !important;

  h2 {
    font-size: 20px;
  }
}

.card_holder {
  padding-left: 10px;
}

.card_holder_mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
  padding-left: 8px;
}

span.starWrap {
  display: inline-block !important;
}

.image_container {
  position: relative;
  border-radius: 14px;
  overflow: hidden;

  .image_placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    background: $light-gray4;
  }

  img,
  .image_placeholder {
    height: 184px;
    width: 100%;
    object-fit: cover;
  }
}

.image_container_mobile {
  width: 40%;
  height: 100%;
}

.image_container_mobile_home {
  width: 100%;
}

.tag {
  margin-right: 8px;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}

.openTime {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: #fff;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.tagsWrap {
  opacity: 0;
}

.tagsWrap_mobile {
  opacity: 1;
}

.container:hover .tagsWrap {
  opacity: 1;
}

.flags {
  margin-right: 8px;

  .flag {
    border-radius: 5px;
    padding: 4px 10px;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    margin: 0 4px 0 0;
  }

  .flag.NEW {
    background: rgba($green, 0.2);
    color: $green;
  }

  .flag.PERCENT_OFF {
    background: rgba($orange, 0.2);
    color: $orange;
  }
}

.container:hover .openTime {
  opacity: 1;
}

.distance {
  position: absolute;
  left: 16px;
  top: 16px;
  height: 28px;
  padding: 6px 12px;
  background: $white;
  box-shadow: 0 4px 16px rgba(45, 42, 49, 0.2);
  border-radius: 8px;
  color: $dark-violet;

  @include media('<desktop') {
    height: 25px;
  }
}

.distance_mobile {
  height: 22px;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  left: 4px !important;
}

button.favorite_icon {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;

  .active {
    .icon_favourite {
      span {
        &:first-child {
          &:before {
            opacity: 1;
            color: $purple;
          }
        }
      }
    }
  }
}

div.title {
  margin: 16px 0;
  padding: 0 !important;
}

div.title_mobile {
  margin: 0;
  padding: 0 !important;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  margin-bottom: 8px;

  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
    margin-bottom: 8px;
  }
}

div.info {
  display: flex;
  flex-flow: wrap;
  padding: 0;
  font-size: 10px;
  margin-top: auto;

  &:last-child {
    padding: 0;
  }

  i {
    font-size: 17px;
    margin: 0 8px 0 0;
    color: $dark-violet;
  }

  span {
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 8px 8px 0;
    font-size: 14px;
    font-weight: 400;
    color: $black;
  }

  span.teg {
    padding: 0 10px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;

    &.popular {
      background: $light-pink;
      color: $dark-pink;
    }

    &.trending {
      background: $light-violet;
      color: $dark-violet;
    }

    &.off {
      background: $light-orange-1;
      color: $dark-orange;
    }
  }

  .votes {
    color: $gray;
  }

  .price {
    font-size: 12px;
  }
}

div.info_mobile {
  display: flex;
  flex-flow: wrap;
  padding: 0;
  font-size: 10px;
  margin-top: 0 !important;

  &:last-child {
    padding: 0;
  }

  i {
    font-size: 17px;
    margin: 0 8px 0 0;
    color: $dark-violet;
  }

  span {
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 8px 8px 0;
    font-size: 14px;
    font-weight: 400;
    color: $black;
  }

  span.teg {
    padding: 0 10px;
    border-radius: 5px;
    font-size: 10px;
    font-weight: 500;

    &.popular {
      background: $light-pink;
      color: $dark-pink;
    }

    &.trending {
      background: $light-violet;
      color: $dark-violet;
    }

    &.off {
      background: $light-orange-1;
      color: $dark-orange;
    }
  }

  .votes {
    color: $gray;
  }

  .price {
    font-size: 12px;
  }
}

.openTimeMobile {
  opacity: 1;
}
