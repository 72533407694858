@import "../../theme/base/variables";
@import '../../theme/vendors/include-media';

.subscribe_content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;

  .subscribe_info {
    width: 40%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include media('<desktop') {
      width: 100%;
    }

    .subscribe_title {
      color: $black;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 9px;

      @include media('<desktop') {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        width: 100%;
        text-align: center;
      }

      .subscribe_subtitle {
        color: $dark-violet;
      }
    }

    .subscribe_description {
      margin-bottom: 15px;
      font-size: 18px;

      @include media('<desktop') {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
        max-width: 80%;
        margin: auto;
        margin-bottom: 16px;
      }
    }

    .subscribe_form {
      display: flex;
      align-items: flex-start;

      .subscribe_field {
        input {
          border: 1px solid $light-gray3;
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
          border-radius: 40px;
          height: 48px;
        }
      }
      .subscribe_btn {
        width: 200px;
        margin-left: 18px;
        height: 48px;
      }
    }
  }

  .subscribe_box {
    width: 40%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.subscribe_content_mobile {
  flex-direction: column;
  align-items: center;
  .subscribe_box {
    width: 80%;
  }
}
