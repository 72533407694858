@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.partnership_content {
  display: flex;
  justify-content: space-around;
  background-color: #6f2ccc;

  @include media("<desktop") {
    display: block;
    position: relative;
  }

  &.row_reverse {
    justify-content: space-between;
    flex-direction: row-reverse;
    @include media("<desktop") {
      margin: 20px 0px;
    }
  }

  .partnership_info {
    width: 40%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    @include media("<desktop") {
      width: 80%;
      margin: auto;
      position: relative;
      z-index: 2;
    }

    .partnership_title {
      color: $white;
      font-weight: 500;
      font-size: 42px;
      line-height: 56px;
      margin-bottom: 9px;

      @include media("<1200px") {
        font-weight: 500;
        font-size: 38px;
        line-height: 48px;
        width: 100%;
      }

      @include media("<900px") {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        width: 100%;
        text-align: center;
      }

      @include media("<600px") {
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        width: 100%;
        text-align: center;
      }

      .partnership_subtitle {
        color: #fdbf3b;
      }
    }

    .partnership_description {
      margin-bottom: 0px;
      font-size: 18px;

      @include media("<desktop") {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
      }
    }

    .partnership_description_second {
      font-weight: 600;
    }

    .partnership_btn {
      height: 56px;
      min-width: 213px;
      font-size: 18px;
      line-height: 24px;
      padding: 12px 20px;
      background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
      border-radius: 28px;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media("<desktop") {
        margin: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        height: 50px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .partnership_box {
    width: 40%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("<900px") {
      width: 40%;
      margin: auto;
    }
    @include media("<600px") {
      width: 60%;
      margin: auto;
    }
  }
}
