@import "../../../../theme/base/variables";

.service_wrap {
  .acc_show {
    box-shadow: none;
    border-radius: 0;
  }

  .acc_show_open {
    min-height: 0 !important;
    padding: 0;
    display: inline-flex;

    p {
      font-size: 14px;
      line-height: 20px;
      color: $black;
    }

    div {
      margin: 0;
      padding: 0;
    }

    svg {
      margin-left: 6px;
    }
  }

  .acc_show_expand {
    padding: 20px 0 0;
  }
}
.service_box {
  padding: 24px 0 0;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  display: flex;
  justify-content: space-between;

  + .service_box {
    border-top: 1px solid $border-button-color;
  }

  h3 {
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 16px;
  }
}

.description {
  max-width: 305px;
}

.button_box {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  div {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
  }
}

.btn {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  border: 1px solid $input-black;
  border-radius: 22px;
  padding: 12px;
  width: 99px;
  height: 44px;
  background: none;
  color: $black;
  display: block;
  margin: 0 0 16px !important;

  &.add_btn {
    color: $white;
    background: $input-black;
  }
}

.old_cost {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.4;
  margin-right: 12px;
  position: relative;
  color: $purple;

  &:after {
    content: "";
    position: absolute;
    left: -3px;
    right: -3px;
    top: 50%;
    transform: translateY(-50%) rotate(162deg);
    height: 1px;
    background: $purple;
    margin-top: -2px;
  }
}

.cost {
  color: $purple;
  font-size: 20px;
}

.btn_show_description {
  padding: 0 !important;
  margin-bottom: 8px !important;
  min-height: 24px !important;
  max-height: 24px !important;

  p {
    color: $black !important;
    font-size: 13px !important;
    font-weight: 400;
    line-height: 16px;
  }
}
