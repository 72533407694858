@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.textarea {
  height: 140px !important;
  min-height: 140px !important;
  resize: none;
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 20px;
  padding: 0 20px;

  &.small_area {
    height: 65px !important;
    min-height: 65px !important;
  }

  &:focus {
    box-shadow: none;
  }
}

.area_box {
  position: relative;
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;
  padding: 14px 0 0;

  .count {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $gray;
    display: block;
    text-align: right;
    padding: 6px 16px 16px;
  }
}