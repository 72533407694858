@import "../../base/variables";

.fc {

  table {
    border: none !important;

    > thead {
      background: $white;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    }

    colgroup {
      col {
        width: 92px !important;
        background: $white;
      }
    }
  }

  .fc-col-header-cell-cushion {
    display: block;
  }

  .fc-scrollgrid-section {
    table {
      tbody {
        tr {
          td {
            &:first-child {
              border: none;
              font-size: 14px;
              line-height: 20px;
              font-weight: 500;
              color: $gray;
              background: $white;
              box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
            }
          }
        }
      }
    }
  }

  .fc-timegrid-event,
  .fc-timegrid-more-link {
    padding: 0;
  }

  .fc-timegrid-event-harness > .fc-timegrid-event {
    border-radius: 16px;
    margin: 0;
    border: none !important;
  }

  .fc-timegrid-event .fc-event-main {
    overflow: visible !important;
  }
}

.fc-theme-standard {
  th {
    border: none;
  }
}

.fc-media-screen .fc-timegrid-cols {
  tbody {
    td {
      //border: none;
      //border-left-color: #000 !important;
    }


  }
}

.fc-theme-standard td,
.fc-theme-standard th {
  border-left: none;

  //border-top-color: $border-button-color;
  //border-top-style: solid;
}

.fc-col-header .fc-timegrid-axis {
  border: 0;
}

.fc-timegrid-slot-label-frame {
  line-height: 0.75rem;
}

.fc .fc-timegrid-slot {
  height: 40px !important;
}

.fc .fc-timegrid-slot-minor {
  border-top-style: solid;
}

.fc .fc-scrollgrid-section-liquid > td {
  &:last-child {
    border: none;
  }
}

.fc-timegrid-event .fc-event-main {
  overflow: auto;
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 16px;
}