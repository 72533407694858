@import "../../theme/vendors/include-media";

.title {
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 32px;

    @include media("<desktop") {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    }
  }
}

.venues_list {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  @include media('<=tablet') {
    display: block;
  }

  @include media(">=tablet", "<desktop") {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;
  }

  .venues_item {
    width: 25%;
    padding: 0 10px 34px;

    @include media(">=tablet", "<desktop") {
      width: 50%;
    }
    @include media("<tablet") {
      width: 100%
    }
  }
}
