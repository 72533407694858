@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.visual {
  position: relative;
  height: 660px;
  z-index: 1;
  padding: 34px 20px 0;
  clip-path: ellipse(82% 61% at 50% 39%);
  background: linear-gradient(323.63deg, $gradient-start 0%, $gradient-end 100%);
  overflow: hidden;

  @include media("<desktop") {
    height: 250px;
  }

  &:before {
    content: "";
    width: 115px;
    height: 115px;
    border-radius: 50%;
    position: absolute;
    top: 140px;
    right: -5px;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    opacity: 0.8;
  }

  .visual_holder {
    max-width: 1114px;
    margin: 0 auto;
    min-height: 627px;
    display: flex;

    @include media("<desktop") {
      display: none;
    }
  }
}

.visual_text {
  width: 55%;
  color: $white;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: 150px 0;
  // padding-right: 50px;
  position: relative;
  z-index: 2;

  &:before {
    content: "";
    width: 111px;
    height: 111px;
    border-radius: 50%;
    position: absolute;
    left: -78px;
    top: 155px;
    z-index: -1;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    opacity: 0.8;
  }

  h1 {
    color: $white;
    font-weight: 600;
    font-size: 60px;
    line-height: 80px;
    margin: 0 0 24px;
  }

  @include media("<1200px") {
    width: 55%;
    h1 {
      font-size: 54px;
    }
  }
}

.visual_slider {
  width: 45%;
  position: relative;
  z-index: 1;
  transform: translate(0px, 24px);

  @include media("<1200px") {
    width: 45%;
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 50%;
    margin-left: -61%;
    bottom: -27px;
    width: 567px;
    height: 567px;
    border-radius: 50%;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    opacity: 0.8;
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }

  // img {
  //   width: 485px;
  //   height: 492px;
  //   object-fit: cover;
  // }
}
