@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.marketing_2 {
  height: 560px;
  display: flex;
  justify-content: space-around;
  background-image: url("../../../../Assets/images/background-marketing-2.png");
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .wrapper_text {
    max-width: 600px;
    margin-right: 120px;
    .text {
      font-size: 37px !important;
      font-weight: 500;
      line-height: 56px;
      color: $black;
      text-align: center;
    }
    @include media("<1200px") {
      width: 400px;
      .text {
        font-size: 24px !important;
        line-height: 32px;
      }
    }
  }
  @include media("<900px") {
    height: 1100px;
    background-image: url("../../../../Assets/images/background-marketing-mobile.png");
    background-position: bottom;
    justify-content: center;
    align-items: flex-start;
    .wrapper_text {
      width: 400px;
      margin-right: 0px;
      margin-top: 100px;
      .text {
        font-size: 24px !important;
        line-height: 32px;
      }
    }
  }
  @include media("<600px") {
    .wrapper_text {
      width: 320px;
      margin-right: 0px;
      .text {
        font-size: 20px !important;
        line-height: 28px;
      }
    }
  }
  @include media("<360px") {
    .wrapper_text {
      width: 280px;
      margin-right: 0px;
      .text {
        font-size: 20px !important;
        line-height: 28px;
      }
    }
  }
}
