@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.container_categories {
  padding: 40px 0;
  display: flex;
  flex-wrap: wrap;

  @include media("<desktop") {
    padding: 16px 0px;
    display: block;
  }

  .category {
    min-width: calc(100% / 7);
    max-width: calc(100% / 7);
    margin-bottom: 30px;

    @include media("<desktop") {
      min-width: 100%;
      max-width: 100%;
    }

    .btn_content {
      width: 116px;
      height: 116px;
      margin: 0 auto 20px;
      border-radius: 50%;
      background: $white;
      border: 1px solid $light-gray;
      background-size: 100% 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      @include media('<desktop') {
        padding: 25px 20px 28px;
        width: 80px;
        height: 80px;
      }

      &.btn_other {
        box-shadow: 0 4px 20px rgba(122, 122, 122, 0.15);
      }

      .bnt_dots {
        width: 48px;
        display: flex;
        justify-content: space-between;

        .bnt_dot {
          width: 12px;
          height: 12px;
          background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
          display: block;
          border-radius: 50%;
        }
      }
    }

    .category_text {
      font-size: 20px;
      text-align: center;
      margin: 0;
      color: black;
      @include media("<desktop") {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  .titleCategories {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
  }
}
