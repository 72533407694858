@import "../base/variables";
@import "../vendors/include-media";

.slick-slide {
  outline: none;
  padding: 0 8px;
}

.service-slider {
  .slick-slide {
    width: 433px;
    padding: 0 10px;
    @include media("<tablet") {
      max-width: 100%;
      width: 100%;
    }
  }
}

.venue-slider {
  .slick-slide {
    padding: 0 10px;
    max-width: calc((100% / 4) - 20px);

    @include media(">=tablet", "<=widescreen") {
      max-width: 100%;
    }

    @include media("<tablet") {
      max-width: 100%;
      width: 100%;
    }
  }
}

.staff-slider {
  margin-left: -28px;

  @include media("<tablet") {
    margin-left: 0px;
  }

  .slick-slide {
    width: 164px;
    max-width: calc(100% / 6);

    @include media("<tablet") {
      max-width: 100%;
      width: 100%;
    }
  }
}

.booking-staff-slider {

  .slick-slide {
    width: 92px !important;
  }
}