// @import "../../../../theme/base/variables";

.divider {
  background-color: #f3f3f6;
  width: 100%;
  height: 1px;
  margin: 20px 0 16px;
}

.otherCardText {
  color: #1f1e20;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
}
