.gm-style-mtc {
  display: none;
}

.gm-style .cluster img {
  display: none;
}

.gm-style .cluster {
  height: 32px !important;
  width: 32px !important;
}

.gm-style .cluster > div {
  margin: auto;
  height: 30px !important;
  width: 30px !important;
  background-color: black;
  border-radius: 50%;
  border: 2px solid #fff;
  line-height: 26px !important;
  color: #fff !important;
}

.gm-style .gm-fullscreen-control,
.gm-style .gmnoprint,
.gm-style .gm-style-cc,
.gm-style .gm-svpc {
  display: none;
}

.gm-style .gm-control-active img {
  width: 10px !important;
  height: 10px !important;
}

.map-contrl {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  right: 16px;
  top: 32px;
}

.map-contrl button {
  background-color: #fff;
  border-radius: 8px;
  width: 48px;
  height: 48px;
}
.map-contrl button:first-child {
  margin-bottom: 8px;
}

@media (max-width:  1024px) {
  .map-contrl button {
    width: 35px;
    height: 35px;
  }
  .map-contrl { 
    z-index: 1;
    top: 16px;
    right: 16px
  }
}

#map {
  height: 100%;
  width: 100%;
}

.marker-cluster {
  background-color: #000;
  border-radius: 50%;
  border: 2px solid #fff;
  width: 36px;
  height: 36px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  position: relative;
  top: -18px;
  left: -18px;
}