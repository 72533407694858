@import "../../theme/base/variables";

.subForgetTheme {
  font-size: 16px;
  font-weight: 400;
  padding-top: 20px;
  color: $gray;
}

.main-card-wrapper-theme {
  padding: 0 0 20px;
}

.card-wrapper-info {
  padding: 0 0 25px;
}