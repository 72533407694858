@import "../../../../theme/base/variables";

.booking_info {
  color: $gray;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 24px;

  + .booking_info {
    border-top: 1px solid #fafafa;
  }

  p {
    margin: 0;
  }

  time {
    color: $black;
  }

  ul {
    margin-top: 4px;
    margin-bottom: 0px;
  }
}

.heading {
  color: $black;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  margin: 0 0 6px;


  span {
    color: $purple;
  }

  .packages {
    color: $gray;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    top: -2px;
  }
}

.old_cost {
  font-size: 14px;
  line-height: 20px;
  font-weight: normal;
  opacity: 0.4;
  margin-right: 12px;
  position: relative;
  color: $purple;

  &:after {
    content: "";
    position: absolute;
    left: -3px;
    right: -3px;
    top: 50%;
    transform: translateY(-50%) rotate(162deg);
    height: 1px;
    background: $purple;
    margin-top: -2px;
  }
}