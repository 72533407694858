@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.radio_section {
  display: flex;
  flex-wrap: wrap;

  .form_radio_btn {
    margin: 0 16px 16px 0;

    label {
      display: block;
      cursor: pointer;
      border: 1px solid $border-button-color;
      box-sizing: border-box;
      border-radius: 40px;
      user-select: none;
      font-size: 12px;
      line-height: 16px;
      color: $gray;
      height: 36px;
      text-align: center;
      padding: 10px 18px;

      @include media("<desktop") {
        cursor: none;
      }
    }

    input[type="radio"] {
      display: none;
    }

    input[type="radio"]:checked + label {
      color: $purple;
      border-color: $purple;
      white-space: nowrap;

      @include media("<desktop") {
        font-weight: 500;
        font-size: 10px;
        line-height: 12px;
      }
    }

    input[type="radio"]:disabled + label {
      opacity: 0.6;
      cursor: default;
    }
  }
}
