@import "../../theme/base/variables";

.checkboxLabel {
  display: inline-block;
}

.forgot-psw-wrapper {
  padding-top: 12px;
}

.wrapper-password-sign-up {
  display: flex;
  flex-direction: column;
  padding-bottom: 16px !important;
  .wrapper-input-password-sign-up {
    position: relative;
    .wrapper-icon-show-password {
      position: absolute;
      right: 8px;
      top: 28px;
    }
  }
}
