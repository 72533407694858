@import "../base/variables";

// custom select

.select-formControl {
  background: $white;
  box-shadow: 0px 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;
  height: 56px;
  width: 100%;
  margin-bottom: 16px;

  svg {
    opacity: 0;
  }

  > div {
    margin: 0;
    color: $black;
    position: relative;
    font: 14px/20px $base-font-sans-serif;

    > div {
      box-sizing: border-box;
      height: 56px;
      padding: 14px 24px !important;

      &:after {
        font-family: "icomoon", sans-serif !important;
        speak: never;
        font-style: normal;
        font-weight: normal;
        font-feature-settings: normal;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        content: "\e907";
        position: absolute;
        right: 18px;
        top: 18px;
        font-size: 17px;
        color: $gray;
      }

      // &[aria-expanded = "true"] {
      //   &:after {
      //     transform: rotate(180deg);
      //   }
      // }
    }
  }
}

.MuiPopover-paper {
  border: 1px solid $border-button-color;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  margin-top: 8px;

  ul {
    li {
      padding: 8px 24px;
      color: $purple;
      font-family: $base-font-sans-serif;
    }

    .notes-select {
      min-width: 17px;
      height: 17px;
      display: inline-block;
      vertical-align: top;
      border-radius: 50%;
      color: $white;
      text-align: center;
      padding: 1px 2px 0;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
      margin-top: -2px;
      margin-left: 10px;
      background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    }
  }
}

.MuiListItem-root.Mui-selected,
.MuiListItem-root.Mui-selected:hover {
  background: none !important;
}
