@import "./base/normalize";
@import "./base/variables";
@import "./base/form";
@import "./base/typography";
@import "./base/reset";
@import "./base/btns";
@import "./components/components";
@import "./components/icomoon";
@import "./vendors/include-media";
@import url("https://fonts.googleapis.com/css2?family=Epilogue:wght@400;500;600&display=swap");

html,
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  // height: 100%;
}

#root,
#root .wrapper {
  min-height: 100vh;
  height: 100%;
  overflow: hidden;
}

.MuiAutocomplete-popper {
  @include media("<desktop") {
    width: 100% !important;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

main {
  flex: 1;
}

main > div {
  flex: 1;
  height: 100%;
  min-height: 500px;
  @include media("<desktop") {
    min-height: 100%;
  }
}

.no-padding-root {
  padding: 0 !important;

  > div {
    padding: 0 !important;
  }
}

.dashboard-title {
  font-size: 20px;
  line-height: 24px;
}

.card-wrapper {
  background: $white;
  padding: 32px;
  margin: 0 0 20px;
  @include media("<desktop") {
    padding: 0
  }

  @include media(">=tablet", "<=widescreen") {
    padding: 0 32px 32px;
  }
}

.card-wrapper-section {
  margin: 0 auto;
  padding: 0 20px 20px;
  width: 100%;
}

.wrapper-medium {
  max-width: 670px;
}

.wrapper-large {
  max-width: 846px;
}

.wrapper-signin {
  max-width: 494px;
}

.wrapper-forgot-password {
  max-width: 454px;
}

.main-card-wrapper-theme {
  margin: 0;
  padding: 0 0 32px;
}

.sub-theme-color {
  color: $gray;
}

.agreement {
  font-size: 14px;
}

.main-small-card-wrapper-theme {
  font-size: 20px;
  font-weight: 600;
  padding: 0 0 16px;
}

.card-wrapper-info {
  font-size: 16px;
}

.text-field {
  width: 100%;

  &.text-field-no-border,
  &.text-field-change {
    input {
      border: none !important;
    }
  }

  &.text-field-change {
    .MuiOutlinedInput-adornedEnd {
      padding-right: 24px;
    }

    .change-btn {
      color: $purple;
      border: none;
      padding: 0;
      background: none;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.Unverified,
.Verified {
  color: $gray;
  font-size: 12px;
  line-height: 16px;
}

.Verified {
  color: $purple;
}

.MuiPaper-outlined {
  border: none !important;
}

.MuiPaper-rounded {
  border-radius: $paper-border-radius ;
}

.card-footer {
  padding-top: 24px;
  padding-bottom: 0;
  font-size: 14px;
}

.verification-wrapper {
  //padding-top: 20px;
}

.MuiButton-outlined.Mui-disabled {
  border: none !important;
}

a {
  text-decoration: none;
  color: $purple;
}

.sub-card-wrapper-info {
  font-size: 16px;
  color: $gray;
  padding-top: 24px;
}

.alert-message {
  font-size: 14px;
  padding-top: 14px;

  &:empty {
    padding-top: 0;
  }
}

.alert-message-color {
  color: $alert-color;
}

.success-message-color {
  color: $success-color;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

.lds-dual-ring {
  display: inline-block;
  width: 24px;
  height: 24px;
}
.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 12px;
  height: 12px;
  margin: 6px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.MuiTypography-body1 {
  font-weight: 500;
  font-family: $base-font-sans-serif !important;
}

.align-items-flex-start {
  align-items: flex-start !important;
}

.checkboxLabel-control {
  .MuiFormControlLabel-label {
    padding: 12px 0 0;
    font-weight: 500;
    display: block;
  }
}

.title-header {
  color: $input-black;
  margin: 0;
}

.text-field-verified .MuiOutlinedInput-adornedEnd {
  padding-right: 24px;
}

.footer-at-bottom {
  display: flex;
}

.footer-at-bottom-holder {
  flex: 1 1 auto;
  display: flex;
  flex-flow: column nowrap;
  min-height: calc(100vh - 136px);

  &.footer-at-bottom-holder-venue {
    min-height: calc(100vh - 132px);
  }
}

.footer-at-bottom-frame {
  flex: 1 1 auto;
}

.submit-footer {
  background: $white;
  text-align: right;
  min-height: 88px;
  padding: 20px 80px;
  display: flex;
  align-items: center;
  width: 100%;
  box-shadow: 4px 0 10px rgba(0, 0, 0, 0.05);

  @include media("<desktop") {
    padding: 20px 20px;
  }
}

.submit-btn-wrapper {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
}

.submit-btn-save-changes {
  font-size: 14px;
}

.main-header {
  background: $white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  padding: 24px 80px;
  min-height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 40px;
  position: relative;

  @include media("<desktop") {
    padding: 24px 20px;
  }

  .MuiButtonBase-root .MuiSvgIcon-root {
    width: 14px;
    margin: 0 8px 0 0;
  }
}

.header-section {
  &:not(.static-header) {
    header.MuiPaper-elevation4 {
      @include media('>phone') {
        background: $white;
      }
      box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);

      .header-logo {
        .logo_img {
          opacity: 0;
          visibility: hidden;
          @include media('<desktop') {
            opacity: 1;
            visibility: inherit;
          }
        }

        .logo_img_scroll {
          opacity: 1;
          visibility: visible;
        }
      }

      .header-select {
        color: $black;
        background: $white;
        border: 1px solid $light-gray3;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

        &[aria-controls="menu-list-grow"] {
          i {
            color: red;
          }
        }

        .MuiSelect-iconOpen {
          fill: $purple;
        }

        > div {
          color: $black;

          > div {
            &[aria-expanded="true"] {
              color: $purple;

              i {
                color: $purple;
              }
            }
          }
        }

        svg {
          fill: $black;
        }
      }

      svg {
        color: #1f1e20;
      }

      button,
      .submit-btn-save-changes.MuiButton-root.with-icon {
        color: $black !important;
        background: $white;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        border: 1px solid $light-gray3;
        @include media('<desktop') {
          box-shadow: none;
          border: none;
        }

        &:hover {
          color: $black !important;
          background: $white !important;
          border: 1px solid $light-gray3 !important;

          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }

        svg {
          fill: $black;
        }

        &[aria-controls="menu-list-grow"] {
          color: $purple;

          svg {
            fill: $purple;
          }

          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .submit-btn-save-changes.MuiButton-root.with-icon {
        &:after {
          border-top-color: $black;
        }
      }

      .header-button-sign-in {
        color: $purple !important;
        background: $white;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        border: 1px solid $light-gray3;

        &:hover {
          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .drop-btn {
        &:hover {
          &:after {
            opacity: 0;
            visibility: hidden;
          }
        }
      }

      .header-button-partner {
        color: $white !important;

        &:after {
          opacity: 1;
          visibility: visible;
        }
      }

      .search_panel {
        background: $white;
        border: 1px solid $light-gray3;
        box-sizing: border-box;
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 24px;

        button {
          box-shadow: none;
        }

        svg {
          fill: $gray;
        }
      }

      .search_input {
        label {
          color: $gray;
        }

        input[type="search"] {
          color: $gray;
        }
      }

      .header-button-sign-up {
        color: $white !important;
        background: $purple;
      }
    }
  }
}

.static-header header.MuiAppBar-positionFixed,
.static-header-white header.MuiAppBar-positionFixed {
  box-shadow: none;
}

.static-header-white.fixed-position {
  margin: 0;
}

.static-header-white.fixed-position header.MuiAppBar-positionFixed {
  position: fixed !important;
}

.static-header-white {
  margin: 0 0 22px;

  header.MuiAppBar-positionFixed {

    @include media('>phone') {
      background: $white;
    }
    box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);

    .header-logo {
      .logo_img {
        opacity: 0;
        visibility: hidden;

        @include media('<desktop') {
          opacity: 1;
          visibility: inherit;
        }
      }

      .logo_img_scroll {
        opacity: 1;
        visibility: visible;
      }
    }

    .header-select {
      color: $black;
      background: $white;
      border: 1px solid $light-gray3;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

      &[aria-controls="menu-list-grow"] {
        i {
          color: red;
        }
      }

      .MuiSelect-iconOpen {
        fill: $purple;
      }

      > div {
        color: $black;

        > div {
          &[aria-expanded="true"] {
            color: $purple;

            i {
              color: $purple;
            }
          }
        }
      }

      svg {
        fill: $black;
      }
    }

    button,
    .submit-btn-save-changes.MuiButton-root.with-icon {
      color: $black !important;
      background: $white;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border: 1px solid $light-gray3;

      &:hover {
        color: $black !important;
        background: $white !important;
        border: 1px solid $light-gray3 !important;

        @include media("<desktop") {
          color: $black !important;
          background: initial !important;
          border: 1px solid $light-gray3 !important;
          border: none !important
        }

        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }

      svg {
        fill: $black;
      }

      &[aria-controls="menu-list-grow"] {
        color: $purple;

        svg {
          fill: $purple;
        }

        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .submit-btn-save-changes.MuiButton-root.with-icon {
      &:after {
        border-top-color: $black;
        opacity: 1 !important;
        visibility: visible !important;
      }
    }

    .header-button-sign-in {
      color: $purple;
      background: $white;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border: 1px solid $light-gray3;

      &:hover {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .drop-btn {
      &:hover {
        &:after {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    .header-button-partner {
      color: $white !important;

      &:after {
        opacity: 1;
        visibility: visible;
      }
    }

    .search_panel {
      background: $white;
      border: 1px solid $light-gray3;
      box-sizing: border-box;
      box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
      border-radius: 24px;

      button {
        box-shadow: none;
      }

      svg {
        fill: $gray;
      }
    }

    .search_input {
      label {
        color: $gray;
      }

      input[type="search"] {
        color: $gray;
      }
    }
  }
}

.header-holder {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.logo-container {
  width: 141px;
}

.logo-container img {
  width: 100%;
}

.MuiBackdrop-root {
  background: rgba(0, 0, 0, 0.2) !important;
}

.MuiPaper-elevation16 {
  box-shadow: none !important;
}

.main-container {
  padding: 116px 80px 60px 20px !important;
}

.dashboard-frame {
  background: $white;
  border: 1px solid $border-button-color;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02);
  border-radius: 14px;
  padding: 24px;
}

.MuiTabs-indicator {
  background-color: $dark-purple !important;
}

.Mui-selected {
  color: $dark-purple !important;
}

//tabs

header.tab-block {
  background: none;
  box-shadow: none;
  margin: -24px -32px 0;
  width: auto;
  border-bottom: 1px solid $border-button-color;

  &.tab-block-staff {
    margin: -24px -25px 20px;
  }

  &.tab-block-add-staff {
    margin: 0 0 20px;
  }

  &.tab-block-booking {
    margin: 0;

    .tab-holder {
      margin: 0;
      background: none;

      .tab-frame {
        padding: 0;

        button {
          width: 50%;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  &.tab-dialog {
    margin: -24px -24px 0;

    button {
      flex: 1 0 0;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &.tab-block-m-0 {
    margin: 0;
  }

  .tab-holder {
    background: $white;
    margin-top: -40px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.03);
  }

  .tab-frame {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 0 80px;
  }
}

header .tab-btn {
  color: $gray;
  font-size: 16px;
  opacity: 1;
  padding: 24px 30px 16px;
  font-weight: normal;

  @include media(">=600px") {
    min-width: 0;
  }

  @include media("<tablet") {
    padding: 10px;
  }
}

//dashboard table

.dashboard-table-holder {
  background: $white !important;
  border: 1px solid $border-button-color !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.02) !important;
}

.status {
  font-size: 14px;
  padding: 6px 10px;
  display: inline-block;
  vertical-align: top;
  color: $green;
  background: $light-green;
  border-radius: 14px;

  &.paid {
    color: $turquoise;
    background: $turquoise-light;
  }

  &.confirmed {
    color: $blue-2;
    background: $light-blue-4;
  }

  &.cancelled {
    color: $red;
    background: $light-red3;
  }

  &.canceled {
    color: $red;
    background: $light-red3;
  }

  &.completed {
    color: $green;
    background: $light-green;
  }

  &.risk {
    color: $orange;
    background: $light-orange;
  }

  &.lost {
    color: $light-red;
    background: $light-red2;
  }

  &.partial {
    color: $blue;
    background: $light-blue-2;
  }

  &.white{
    color: $purple;
    background:  none;
  }

  &.more{
    color: $purple;
    background:  $light-purple;
  }

  &.tag{
   color: $gray;
   background:  none;
  }

  &.verification{
    color: $blue-1;
    background: $light-blue-3;
  }

  i{
    display: inline-block;
    vertical-align: top;
    margin: 3px 6px 0 0;
  }
}

.dashboard-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
  overflow: hidden;

  td,
  th {
    border-bottom: 1px solid $border-button-color !important;
    border-left: 1px solid $border-button-color;
    padding: 0.5em 1em;

    @include media(">phone") {
      display: table-cell;
    }

    &:first-child {
      border-left: none;
    }
  }

  th {
    color: $gray;
    font: 14px/20px $base-font-sans-serif;

    &:first-child {
      @include media(">=1400px") {
        min-width: 264px;
      }
    }

    @include media("<=phone") {
      display: none;
    }

    @include media(">phone") {
      padding: 16px 2.5vw;
    }
  }

  td {
    color: $black;
    font: 16px/20px $base-font-sans-serif;

    @include media("<=phone") {
      display: block;
    }

    @include media(">phone") {
      padding: 14px 2.5vw 14px 2.5vw;
    }

    &:before {
      content: attr(data-th) ": ";
      font-weight: bold;
      width: 120px;
      display: inline-block;
      color: #000;

      @include media(">phone") {
        display: none;
      }
    }
  }

  .col1 {
    @include media(">phone") {
      width: 30.48%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 27.56%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 41.96%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 15.56%;
    }
  }

  .client-box {
    display: inline-flex;
    align-items: center;

    &:hover {
      .name {
        color: $purple;
      }
    }
  }

  .avatar {
    width: 32px !important;
    height: 32px !important;
    border: 1px solid $white;
    margin: 0 13px 0 0;
    filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
  }

  .icon-surprise {
    color: $purple;
    font-size: 14px;
    margin: 0 0 0 13px;
  }

  .services {
    color: $gray;
  }

  .name {
    display: block;
    font-size: 14px;
    line-height: 20px;
    color: $black;
    font-weight: 500;
  }

  .hours {
    display: block;
    font-size: 12px;
    line-height: 16px;
    color: $gray;
  }

  .time-box {
    text-align: center;
    padding: 14px 6px;
    display: block;
    font-size: 12px;
    line-height: 16px;
    background: $white;
    border: 1px solid $border-button-color;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.03);
    border-radius: 8px;
    color: $purple;
    position: relative;

    &:hover {
      button {
        visibility: visible;
        opacity: 1;
      }
    }

    button {
      font-size: 0;
      line-height: 0;
      padding: 0;
      position: absolute;
      border: none;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      background: $white;
      visibility: hidden;
      opacity: 0;
      transition: all 0.3s ease-in-out;

      &:after {
        content: "";
        width: 32px;
        height: 32px;
        border-radius: 50%;
        background: $light-purple;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      span {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;

        &:before,
        &:after {
          content: "";
          width: 12px;
          height: 1px;
          background: $purple;
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 1;
          transform: translate(-50%, -50%);
        }

        &:after {
          width: 1px;
          height: 12px;
        }
      }
    }
  }
}

.dashboard-stafflist-table {
  .col1 {
    @include media(">phone") {
      width: 28.18%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 21.81%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 17.56%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 18.53%;
    }
  }

  .col5 {
    @include media(">phone") {
      width: 13.92%;
    }
  }
}

.dashboard-closeddate-table {
  .col1 {
    @include media(">phone") {
      width: 32.43%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 16.6%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 24.9%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 26.07%;
    }
  }
}

.dashboard-roster-table {
  th {
    @include media(">phone") {
      padding: 16px;
    }
  }

  td {
    @include media(">phone") {
      padding: 6px;
    }

    &:first-child {
      @include media(">phone") {
        padding: 10px 16px;
      }
    }
  }

  .col1 {
    @include media(">phone") {
      width: 12.5%;
    }
  }

  .col-same {
    @include media(">phone") {
      width: 12.5%;
    }
  }
}

.dashboard-table-margin {
  margin: 0 0 40px;
}

.dashboard-user-permissions-table {
  .col1 {
    @include media(">phone") {
      width: 38.22%;
    }
  }

  .col2,
  .col3,
  .col4,
  .col5 {
    @include media(">phone") {
      width: 15.445%;
    }
  }
}

.dashboard-timeoutSettings-table {
  .col1,
  .col2,
  .col3,
  .col4 {
    @include media(">phone") {
      width: 25%;
    }
  }
}

.dashboard-shifts-table {
  th {
    @include media(">phone") {
      padding: 16px 24px;
    }
  }

  td {
    @include media(">phone") {
      padding: 19px 24px;
    }
  }

  .col1 {
    @include media(">phone") {
      width: 20%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 20.75%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 13.51%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 25.96%;
    }
  }

  .col5 {
    @include media(">phone") {
      width: 19.78%;
    }
  }
}

.dashboard-invoices-table {
  th {
    @include media(">phone") {
      padding: 16px 24px;
    }
  }

  td {
    @include media(">phone") {
      padding: 19px 24px;
    }
  }

  .col1 {
    @include media(">phone") {
      width: 13.073%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 18.862%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 14.471%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 20.558%;
    }
  }

  .col5 {
    @include media(">phone") {
      width: 11.077%;
    }
  }

  .col6 {
    @include media(">phone") {
      width: 21.959%;
    }
  }
}

.dashboard-voucher-table {
  th {
    @include media(">phone") {
      padding: 16px 24px;
    }
  }

  td {
    @include media(">phone") {
      padding: 19px 24px;
    }
  }

  .col1 {
    @include media(">phone") {
      width: 13.173%;
    }
  }

  .col2 {
    @include media(">phone") {
      width: 17.546%;
    }
  }

  .col3 {
    @include media(">phone") {
      width: 17.546%;
    }
  }

  .col4 {
    @include media(">phone") {
      width: 14.87%;
    }
  }

  .col5 {
    @include media(">phone") {
      width: 16.467%;
    }
  }

  .col6 {
    @include media(">phone") {
      width: 16.167%;
    }
  }
}

.TabPanel {
  > div {
    padding: 0 !important;
  }
}

.user-permissions {
  padding: 20px 0 0;

  h3 {
    font-size: 20px;
    margin: 0 0 16px;
  }
}

.table-checkbox {
  margin: 0 !important;

  svg {
    width: 30px;
    height: 30px;
  }
}

// container section

.main-container-section {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px 60px;

  @include media("<desktop") {
    padding: 0 20px 20px;
  }

}

.main-container-section_card {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0;

  @include media("<desktop") {
    padding: 0;
  }

  &.pr_0 {
    padding-right: 0;
  }
}

.container-section {
  background: $white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 24px 32px;
  width: 100%;
}

.main-container-section .paper-box {
  padding: 0;
  display: flex;
  height: 100%;
  box-shadow: none;
  border-radius: 0;
  background: none;

  &.margin-bottom {
    margin-bottom: 20px;
  }
}

.MuiBox-root-8 {
  padding: 0 !important;
}

// upload image

.upload-block {
  color: $gray;
  padding: 0 0 21px;
  font: 12px/16px $base-font-sans-serif;

  .upload-holder {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      margin: 0 0 12px;
    }
  }

  .list-header {
    font-size: 16px;
    line-height: 20px;
    color: $black;
    font-weight: 500;
    display: block;
    margin: 0 0 4px;
  }

  .avatar {
    margin: 0 0 16px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    &.active {
      border: 1px solid $white;
      filter: drop-shadow(0 0 4px rgba(0, 0, 0, 0.1));
    }

    width: 150px;
    height: 150px;
    border: none;
    padding: 0;
    border-radius: 50%;
    background: $light-purple;
    overflow: hidden;

    img {
      position: relative;
      z-index: 1;

      &.preview {
        width: 100%;
        height: 100%;
      }
    }
  }

  .btn-upload {
    background: $white;
    border-radius: 8px;
    padding: 6px 16px;
    color: $purple;
    box-shadow: none;
    text-transform: none;

    &:hover {
      background: $white;
      box-shadow: none;
    }

    &.Mui-disabled {
      color: $purple !important;
      background: $light-purple;
    }

    i {
      margin-right: 9px;
    }
  }

  .upload-message {
    padding-top: 11px;
    color: $light-red;
  }
}

// alert box

.alert-box {
  .modal-dialog & {
    background: $light-purple4;
    border-radius: 8px;
    padding: 14px 70px 14px 20px;
    font-size: 14px;
    line-height: 20px;
    color: $purple;
    box-shadow: none;

    div {
      padding: 0;
    }

    .MuiAlert-icon {
      display: none;
    }
  }
}

.MuiPickersDay-daySelected {
  background: $submit-button-color !important;
}

//timepicker

.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
  background: $submit-button-color;
}

.MuiPickersClockPointer-thumb {
  border-color: $purple !important;
}

.MuiButton-textPrimary {
  color: $purple !important;
}

// tooltip
.MuiTooltip-popper {
  > div {
    background: rgba($black, 0.4);
    font-size: 14px;
    line-height: 20px;

    span {
      &:before {
        background: $black;
        opacity: 0.4;
      }
    }
  }
}

//drop-down

.drop-down {
  position: relative;

  &:hover {
    .drop-holder {
      visibility: visible;
      opacity: 1;
    }

    > a {
      color: $purple;
      background: $white;

      &:after {
        transform: rotate(180deg);
      }
    }
  }

  > a {
    display: inline-block;
    vertical-align: top;
    border: 1px solid $purple;
    padding: 9px 20px;
    padding-right: 42px;
    border-radius: 14px;
    background: $submit-button-color;
    color: $white;
    font: 500 14px/20px $base-font-sans-serif;
    position: relative;

    &:after {
      font-family: 'icomoon', sans-serif !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e907";
      position: absolute;
      right: 22px;
      top: 12px;
      transition: transform 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
  }

  .drop-holder {
    padding: 8px 0 0;
    position: absolute;
    right: 0;
    top: 100%;
    visibility: hidden;
    opacity: 0;
    z-index: 3;
    transition: all 155ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &.position-top {
      top: auto;
      bottom: 100%;
      padding: 0 0 8px;
    }
  }

  ul {
    padding: 16px;
    margin: 0;
    list-style: none;
    background: $white;
    border: 1px solid $border-button-color;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 14px;
    font: 500 14px/20px $base-font-sans-serif;
    width: 193px;

    li {
      padding: 0 0 12px;

      &:last-child {
        padding: 0;
      }
    }

    button {
      color: $black;
      padding: 0 !important;
      background: none;
      border: none;

      &:hover {
        color: $purple;
        background: none;
      }

      &.alert {
        color: $alert-color;
      }
    }

    .submit-btn-save-changes.MuiButton-root {
      padding: 0 !important;
      background: none;
      border-radius: 0;
      color: $black !important;

      &:hover {
        color: $purple !important;
      }
    }
  }
}

// color section
.color-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  border: 1px solid $border-button-color;
  box-sizing: border-box;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 20px;
  margin: 0 0 24px;

  .color-box {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;

    label {
      opacity: 0.3;
      width: 30px;
      height: 30px;
      box-shadow: none;
    }

    label.check {
      opacity: 1;
      width: 36px;
      height: 36px;
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);
    }
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }
  [type="radio"]:checked + label,
  [type="radio"]:not(:checked) + label
  {
    cursor: pointer;
    display: block;
    position: relative;
    border: 1px solid $white;
    border-radius: 4px;
  }
}

.services-section {
  .dashboard-frame {
    margin: 0 0 12px;

    .search-form-margin {
      margin: 0;
    }

    .search-form {
      border: none;
      padding: 0;
    }
  }
}

.upload-photo-indent {
  padding: 0 0 11px;
}

.recharts-wrapper .recharts-cartesian-grid-vertical line {
  stroke-opacity: 0;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line {
  stroke: $border-button-color;
}

.recharts-layer.recharts-cartesian-axis.recharts-yAxis.yAxis line,
.recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis line{
  stroke-opacity: 0;
}

.recharts-default-legend .recharts-surface {
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}

.makeStyles-formControl-30 {
  margin: 0 !important;
}

.avatar-title {
  font-size: 12px;
  line-height: 16px;
  color: $black;

  .avatar {
    width: 44px;
    height: 44px;
    margin: 0 0 4px;
    border: 2px solid $white;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.1));
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
}

.fc .fc-timegrid-now-indicator-arrow {
  border-color: #39B18D;
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}

.fc .fc-timegrid-now-indicator-line {
  border-color: #39B18D;
}

// thead {
//   div.fc-scroller,
//   .fc .fc-scroller-harness {
//     overflow: visible !important;
//   }
// }

.right-side-section {
  display: flex;
  margin-top: -40px;

  .col1 {
    width: 62.361%;
    padding: 40px 80px;
  }

  .col2 {
    width: 37.639%;
    min-height: calc(100vh - 96px);
    overflow-y: auto;
    background: $white;
    border-left: 1px solid $border-button-color;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  }
}

.fc-day-today {
  .number {
    color: $purple;
    border-color: $purple;
  }
}

.divider {
  display: block;
  height: 8px;
  margin-bottom: 60px;
  margin-top: 0;
  background: $light-gray4;
  border:none;

  @include media("<desktop") {
    margin-bottom: 30px;
  }
}

.driver-second {
  margin-bottom: 0px;
}

iframe#launcher-frame {
  z-index: 1200 !important;
}

.auth-title.dialog-title h3 {
  font-size: 24px;
}

.container-holder-tab {
  max-width: 1440px;
  margin: 0 auto;
  padding: 10px 80px 100px;

  @include media("<desktop") {
    padding: 0 20px 100px;
  }
  @include media('>=1024px', '<1440px') { 
    padding: 90px 26px 100px;
  }
}

.tab-panel {
  display: flex;
  flex-grow: 1;
  background-color: #fff;
  padding-right: 108px;
  padding-bottom: 100px;
  padding-top: 100px;
  position: relative;
  margin-top: 0px;
  margin-bottom: -100px;

  @include media(">=tablet", "<=widescreen") {
    padding-top: 50px;
  }

  &.tab-panel-venue {
    padding: 0;
    margin: 0;
    &::before {
      display: none;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: 413px;
    top: 0;
    bottom: 0;
    width: 14px;
    box-shadow: 7px 0 9px rgba(122, 122, 122, 0.05) inset;

    @include media(">=tablet", "<=widescreen") {
      left: 30%;
    }
  }

  h2,
  h3 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  h3 {
    padding-top: 26px;
    font-size: 24px;
    line-height: 28px;
  }

  .col1-policy {
    width: 468px !important;
  }

  .col1 {
    width: 413px;
    padding-right: 80px;

    @include media(">=tablet", "<=widescreen") {
      width: 30%;
    }
  }

  .col2 {
    width: calc(100% - 413px);
    padding-left: 129px;

    @include media(">=tablet", "<=widescreen") {
      width: 70%;
    }

    > div {
      > div {
        padding: 0;
      }
    }
  }

  .tabs-block {
    > div {
      > div {
        + span {
          display: none;
        }
      }
    }
  }

  .tab-btn {
    width: 100%;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    max-width: none;
    text-align: left;
    min-height: 60px;
    border-radius: 8px;
    padding: 18px 32px;
    opacity: 1;

    &[aria-selected="true"] {
      color: $white !important;
      background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
    }

    span {
      display: block;
    }
  }

  .tab-button {
    width: 90%;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: $black;
    max-width: none;
    text-align: left;
    height: 72px;
    border-radius: 8px;
    padding: 18px 32px;
    opacity: 1;
    &::after {
      content: "";
      position: absolute;
      right: 31px;
      top: 25px;
      border: 1px solid #EDEEEF;
      width: 22px;
      height: 22px;
      border-radius: 50%;
    }

    &[aria-selected="true"] {
      background: #ffffff;
      border: 1px solid #edeeef;
      box-sizing: border-box;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05%);
      border-radius: 14px;
      color: black !important;
      &::after {
        border: 5px solid $purple;
      }
    }

    span {
      display: block;
    }
  }
}

.slick-slide {
  outline: none !important;
}

.open_datepicker {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
}

.radialProgressBar {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  z-index: 1;
  margin-bottom: 8px;
}

.radialProgressBar .overlay {
  border-radius: 50%;
  width: 36px;
  height: 36px;
  margin: auto;
  background: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $purple;
}

.progress-25 {
  background-image: linear-gradient(
      90deg,
      $border-button-color 50%,
      transparent 50%
    ),
    linear-gradient(180deg, $purple 52%, $border-button-color 50%);
}

.progress-50 {
  background-image: linear-gradient(
      90deg,
      $border-button-color 50%,
      transparent 50%
    ),
    linear-gradient(270deg, $purple 52%, $border-button-color 50%);
}

.progress-75 {
  background-image: linear-gradient(360deg, $purple 50%, transparent 50%),
    linear-gradient(270deg, $purple 50%, $border-button-color 50%);
}

.progress-100 {
  background-image: linear-gradient(90deg, $purple 50%, transparent 50%),
    linear-gradient(-90deg, $purple 50%, $border-button-color 50%);
}

.MuiList-root.MuiMenu-list.MuiList-padding {
  max-width: 430px;
  max-height: 290px;
}

.MuiButtonBase-root.MuiListItem-root.MuiMenuItem-root.TimePickerStyle_MenuItem__1cxXv.Mui-selected.MuiMenuItem-gutters.MuiListItem-gutters.MuiListItem-button.Mui-selected {
  color: $purple !important;
  box-shadow: none;
  background: linear-gradient(
    323.63deg,
    rgba(91, 19, 183, 0.1) 0%,
    rgba(130, 67, 223, 0.1) 100%
  ) !important;
}

.MuiRating-root {
  font-size: 33px !important;
  color: $dark-violet !important;
}

.text-underline {
  text-decoration: underline;
}

.text-bold {
  font-weight: bold;
}

.table-beauty-services {
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 24px;
}

.td-beauty-services, .th-beauty-services {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

.mainDialog {
  @include media("<desktop") {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
.MuiPopover-paper {
  @include media("<desktop") {
    width: 100% !important;
    max-width: 100% !important;
    left: 0!important;
    height: 500px !important;
    bottom: 0 !important;
    top: initial !important;
    border-bottom-left-radius: 0px !important;
    border-bottom-right-radius: 0px !important;

  }

}
.MuiPickersBasePicker-container {
  flex-direction: column !important;
  .MuiPickersBasePicker-pickerView {
    @include media("<desktop") { 
      width: 100% !important;
      max-width: 100% !important;
    }

  }
}
.MuiDrawer-paper {
  border-top-left-radius: 14px !important;
  border-top-right-radius: 14px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
  @include media("<desktop") { 
    border-radius: 0px !important;
  }
}

.MuiDrawer-paperAnchorBottom {
  height: auto !important;
  width: 100% !important;
  max-width: 100% !important;
  left: 0!important;
  bottom: 0 !important;
  top: initial !important;
}

.card-wrapper-second {
  padding: 0px 32px;
  margin-bottom: 0;
}