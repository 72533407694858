@import "../../../../theme/base/variables";

.top_line {
  margin: 0 !important;
}

.bottom_line {
  margin: 0 0 20px 0 !important;
}

.payment_venue {
  width: 100%;
  > div {
    padding: 0;
    margin: 0;
  }
  .info_pay {
    font-size: 16px;
  }
  .btn_add {
    text-align: center;
    padding-top: 4px;
  }
}

.paypal_link {
  width: 100%;
  padding: 16px 119px 16px 20px;
  font-size: 14px;
  background: $light-purple4;
  border-radius: 8px;
}

.accordionSummary {
  &[aria-expanded="true"] {
    p {
      &::after {
        border: 5px solid $purple;
      }
    }
  }
}

.heading {
  padding: 0 0 0 33px;
  position: relative;
  width: 100%;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 2px;
    border: 1px solid $border-button-color;
    width: 22px;
    height: 22px;
    border-radius: 50%;
  }
}

.accordion_details {
  display: flex;
  flex-direction: column;
}
