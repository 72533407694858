.title_box {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  .title_card {
    font-size: 14px;
    color: #7e849b;
    margin-right: 6px;
  }
  img {
    width: 44px;
    padding: 0 6px;
  }
}

.card_info {
  display: flex;
  justify-content: space-between;
  > div {
    &:first-child {
      flex-basis: 48%;
    }
    &:last-child {
      flex-basis: 48%;
    }
  }
}
