@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';

.customer_section {
  z-index: 1;
  position: relative;
  min-height: 357px;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 357px;
    background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
    clip-path: ellipse(73% 68% at 50% 32%);
    z-index: -1;
  }
}

.container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 18px 36px;

  @include media('<desktop') {
    padding: 0px;
  }
}
