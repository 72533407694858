.stripe-payment-form {
  width: 100%;
  align-self: center;
}

.stripe-payment-form .title_box {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
}

.stripe-payment-form .title_box span {
  font-size: 14px;
  color: #7e849b;
  margin-right: 6px;
}

.stripe-payment-form .title_box img {
  width: 44px;
  padding: 0 6px;
}

.stripe-payment-form .text-field input {
  height: 44px !important;
  padding: 12px;
}

.stripe-payment-form .input-wrapper {
  display: block;
}

.stripe-payment-form .alert-message {
  text-align: left;
  margin-bottom: 0;
}

.stripe-payment-form .input-base {
  width: 100%;
  border-radius: 46px;
  border: 1px solid #f1f2f3;
  padding: 14px 24px;
  height: 48px;
  font: 14px/20px Epilogue, Arial, "Helvetica Neue", Helvetica, sans-serif;
}

.stripe-payment-form .input-base.input-invalid {
  border-color: #e4597b;
}

.stripe-payment-form .spacing {
  width: 32px;
}

.wrapper-payment-element {
  pointer-events: none;
  opacity: 0.7;
}

.wrapper-reset-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn-reset-card {
  background: linear-gradient(to bottom, #5b13b7 0%, #8243df 100%);
  border: none;
  border-radius: 30px;
  padding: 12px 32px;
  font-size: 14px;
  color: #fff !important;
}
