@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";
.card {
  box-shadow: 0 4px 20px rgba(89, 89, 89, 0.1);
  border-radius: 14px;
  margin: 8px 16px 32px;

  @include media("<desktop") {
    margin: 8px 0px 32px;
  }
}

.subtotal {
  color: $black;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 24px;
  border-top: 1px solid #fafafa;

  .invalidCard {
    .invalidCardContent {
      display: flex;
      color: #e4597b;
      background-color: #ffecf1;
      padding: 12px 16px;
      border-radius: 8px;
      margin-top: 16px;
      margin-bottom: 16px;
      i {
        margin-right: 8px;
      }
      p {
        margin-bottom: 0;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
      }
    }
    .changeCardBtn {
      color: $purple;
      font-size: 14px;
      line-height: 20px;
      width: 100%;
      background: #ffffff;
      border: 1px solid transparent;
      box-shadow: 0px 4px 20px rgba(234, 234, 234, 0.75);
    }
  }

  .row {
    display: flex;
    justify-content: space-between;

    + .row {
      padding-top: 16px;
    }
  }

  .cost,
  .cost_large {
    color: $purple;
  }

  .cost_large {
    font-size: 18px;
  }
}

.sub_row {
  padding: 0 16px;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;

  @include media("<desktop") {
    padding: 0px;
  }

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin-left: 10px;
  }
}

.mapWrap {
  width: 100%;
  height: 250px;
  padding: 0 16px;
  position: relative;
  @include media("<desktop") {
    padding: 0px;
  }
  & > div {
    overflow: hidden;
    border-radius: 14px;
  }
}

.textMapWrap {
  width: 100%;
  padding: 0 16px;
  position: relative;
  @include media("<desktop") {
    padding: 0px;
  }

  .address {
    font-size: 16px;
    margin-top: 16px;
    color: #1f1e20;
    margin-bottom: 8px;

    img {
      margin-right: 8px;
    }
  }

  .distance {
    color: #808495;
    font-size: 14px;
    padding-bottom: 16px;
  }
}

.service_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $black;
  padding: 0 20px 12px;

  time {
    color: $purple;
    font-weight: normal;
    margin-left: 10px;
  }
}
