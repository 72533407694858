@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.footer {
  color: $white;
  background: $footer-color;
  font-size: 16px;
  line-height: 24px;
  padding: 60px 80px 42px;
  position: relative;
  z-index: 10;

  @include media('<desktop') {
    padding: 60px 20px 10px;
  }

  a {
    color: $white;

    &:hover {
      color: $white;
    }
  }
}

.footer_holder {
  padding: 0 0 42px;
  display: flex;
  justify-content: space-between;
}

.footer_frame {
  border-top: 1px solid rgba($white, 0.2);
  color: rgba($white, 0.8);
  padding: 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    margin: 0;
  }

  ul {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-left: 50px;
    }

    a {
      color: rgba($white, 0.8);
    }
  }
}

.footer_logo {
  display: block;
  width: 146px;
  margin: 0 0 40px;

  @include media('<desktop') {
    display: none
  }

  img {
    width: 100%;
  }
}

.title {
  font-size: 14px;
  line-height: 20px;
  color: rgba($white, 0.6);
  font-weight: normal;
  display: block;
  margin: 0 0 12px;

  @include media('<desktop') {
    display: flex;
    justify-content: center;
  }
}

.heading {
  font-weight: 600;
  font-size: 16px;
  line-height: 32px;
  color: $white;
  display: block;
  margin: 0 0 12px;
}

.item_logo {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;

  @include media("<desktop") {
    width: 100%;
    justify-content: center;
  }

  &.item_download {
    margin-top: 8px;

    @include media('<desktop') {
      display: flex;
      justify-content: center;
    }

    li {
      margin: 0 18px 0 0;
      img {
        width: 120px;
        height: 40px;
      }
      .logo_download {
        object-fit: cover;
      }
    }
  }

  li {
    margin: 0 12px 24px 12px;
    flex-shrink: 0;


  }
}

.left_section {
  width: 254px;
  @include media('<desktop') {
    width: 100%;
  }
}

.right_section {
  width: calc(100% - 380px);
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1024px) {
    flex: 1;
  }

  @include media('<tablet') {
    display: grid;
    grid-template-columns: repeat(2, minmax(0px, 1fr));
    gap: 34px 58px;
    width: 100%;
  }

  > div {
    padding-left: 20px;
    @include media('<desktop') {
      padding: 0px
    }
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 20px;
    color: rgba($white, 0.8);

    &.social_icons {
      opacity: 1;
      display: flex;
      align-items: center;
      font-size: 22px;

      li {
        margin-right: 22px;

      }

      a {
        color: $white;
      }
    }

    li {
      margin: 0 0 16px;
      @include media('<desktop') {
        font-size: 14px;
        line-height: 20px;
      }
    }

    a {
      color: rgba($white, 0.8);
    }
  }
}

.logoCard {
  @include media('<desktop') {
    width: 100%;
  }
  @include media('<desktop') {
    width: 30px;
  }
}
.textCopy {
  text-align: center;
  padding-top: 20px;
  font-size: 15px;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 0px;
}