@font-face {
  font-family: 'icomoon';
  src:  url('../../Assets/icomoon/icomoon.eot?54ieeg');
  src:  url('../../Assets/icomoon/icomoon.eot?54ieeg#iefix') format('embedded-opentype'),
  url('../../Assets/icomoon/icomoon.ttf?54ieeg') format('truetype'),
  url('../../Assets/icomoon/icomoon.woff?54ieeg') format('woff'),
  url('../../Assets/icomoon/icomoon.svg?54ieeg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-phone:before {
  content: "\e92c";
}
.icon-mail:before {
  content: "\e92d";
}
.icon-show-map:before {
  content: "\e92e";
}
.icon-icon-favourite .path1:before {
  content: "\e92a";
  color: rgb(0, 0, 0);
  opacity: 0.4;
}
.icon-icon-favourite .path2:before {
  content: "\e92b";
  margin-left: -1.0908203125em;
  color: rgb(255, 255, 255);
}
.icon-Vector-336-Stroke:before {
  content: "\e927";
}
.icon-Group-3847 .path1:before {
  content: "\e924";
  color: rgb(255, 255, 255);
}
.icon-Group-3847 .path2:before {
  content: "\e925";
  margin-left: -1.3330078125em;
  color: rgb(0, 0, 0);
}
.icon-Group-3847 .path3:before {
  content: "\e926";
  margin-left: -1.3330078125em;
  color: rgb(0, 0, 0);
}
.icon-dollar:before {
  content: "\e923";
}
.icon-credit_card:before {
  content: "\e921";
}
.icon-Vector:before {
  content: "\e922";
}
.icon-trash_full:before {
  content: "\e920";
}
.icon-plus_circle_outline:before {
  content: "\e91f";
}
.icon-user:before {
  content: "\e91e";
}
.icon-off_outline_close:before {
  content: "\e91c";
}
.icon-weather:before {
  content: "\e91d";
}
.icon-edit:before {
  content: "\e91a";
}
.icon-shedule:before {
  content: "\e91b";
}
.icon-plus:before {
  content: "\e918";
}
.icon-square:before {
  content: "\e919";
}
.icon-image_alt:before {
  content: "\e917";
}
.icon-trash_empty:before {
  content: "\e916";
}
.icon-location:before {
  content: "\e915";
}
.icon-download:before {
  content: "\e914";
}
.icon-open-sidebar:before {
  content: "\e912";
}
.icon-close-sidebar:before {
  content: "\e913";
}
.icon-surprise:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e911";
}
.icon-network:before {
  content: "\e910";
}
.icon-vouchers .path1:before {
  content: "\e90b";
  color: rgb(255, 255, 255);
}
.icon-vouchers .path2:before {
  content: "\e90d";
  margin-left: -1.3330078125em;
  color: rgb(126, 132, 155);
}
.icon-vouchers .path3:before {
  content: "\e90f";
  margin-left: -1.3330078125em;
  color: rgb(126, 132, 155);
}
.icon-alert:before {
  content: "\e900";
}
.icon-calendar:before {
  content: "\e903";
}
.icon-clients:before {
  content: "\e904";
}
.icon-dashboard:before {
  content: "\e905";
}
.icon-inventory:before {
  content: "\e906";
}
.icon-sales:before {
  content: "\e908";
}
.icon-services:before {
  content: "\e909";
}
.icon-staff:before {
  content: "\e90a";
}
.icon-venues:before {
  content: "\e90c";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-map-pin-1:before {
  content: "\e929";
}
.icon-star:before {
  content: "\e902";
}
.icon-chevron-down:before {
  content: "\e907";
}
.icon-chevron-up:before {
  content: "\e90e";
}
.icon-exclamation-outline:before {
  content: "\e928";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-linkedin:before {
  content: "\eac9";
}
