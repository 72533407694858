@import '../../../../theme/base/variables';

.container {
  padding: 20px;
  span.colored {
    color: $purple;
  }

  h1 {
    font-size: 22px;
  }
  h3 {
    font-size: 18px;
  }
}
