@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

div {
  .time_picker {
    margin: 0 !important;
    border-radius: 22px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    padding: 0;
    width: 100px;

    @include media("<desktop") {
      background: #7f2fea;
    }

    label {
      color: $purple;
      font-size: 14px;
      line-height: 20px;
      top: -19px;
      left: 15px;
      @include media("<desktop") {
        color: $white;
        font-size: 12px;
      }

      &[data-shrink="true"] {
        display: none;
      }

      + div {
        margin: 0;
      }
    }

    input {
      background: none;
      border: none;
      padding: 6px 16px;
      height: auto;
      width: 100%;
      color: $purple;
      border-radius: 0;
    }

    svg {
      display: none;
    }

    button {
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 0;
      width: 100%;
      box-shadow: none;

      &:hover {
        background: none;
      }

      &:after {
        content: "";
        border-top: 4px solid $purple;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        right: 13px;
        top: 13px;

        @include media("<desktop") {
          border-top-color: $white;
        }
      }
    }
  }
  .time_picker_selected {
    background-color: $purple;

    label {
      color: $white;

      @include media("<desktop") {
        color: $purple;
      }
    }

    @include media("<desktop") {
      background-color: $white;
    }

    input {
      color: $white;

      @include media("<desktop") {
        color: $purple;
        font-size: 12px;
      }
    }
    button {
      &:after {
        border-top: 4px solid $white;
        @include media("<desktop") {
          border-top: 4px solid $purple;
        }
      }
    }
  }
}

.btn_box {
  order: 2;
  padding: 20px 24px 0;
}

.btn_apply {
  background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
  border-radius: 31px;
  font-size: 16px;
  line-height: 22px;
  color: $white;
  padding: 13px;
  margin: 0 0 7px;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;

  @include media("<desktop") {
    width: 300px;
    margin: auto;
  }
}

.btn_clear {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  padding: 13px;
  background: none;
  border: none;
  width: 100%;
  display: flex;
  justify-content: center;
}

.form_control {
  max-width: 50px;
  margin: 15px 26px 0 auto !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  svg {
    margin-top: -2px;
  }
}

.value_time {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;

  &::after {
    content: "";
    border-top: 4px solid $gray;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    right: 0;
    top: 8px;
  }
}

.wrapper_select_am_pm {
  position: absolute;
  top: 24px;
  right: -12px;
  border: 1px solid #edeeef;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;

  .select_am_pm {
    width: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $purple;
    span {
      margin: 8px 0;
    }
    .select_time {
      font-weight: 600;
    }
  }
}
