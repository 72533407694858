@import '../../../../theme/vendors/include-media';

.text {
  margin-bottom: 32px;
  @include media('<desktop') {
    margin-bottom: 24px;
    padding: 0 8px;
  }
  .title {
    font-size: 32px;
    color: #1f1e20;
    margin-bottom: 12px;

    @include media("<desktop") {
      font-size: 24px;
    }
  }
  .subtitle {
    font-size: 18px;
    color: #7e849b;

    @include media("<desktop") {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
