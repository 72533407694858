@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.box {
  position: relative;
}

.dialogRoot {
  position: relative;
}

.autocomplete {
  width: auto !important;
  min-width: 117px;
  height: 32px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  padding: 6px 31px 6px 16px;
  position: static !important;
  cursor: pointer;
  @include media("<desktop") {
    background: #7f2fea;
    min-width: 100px;
  }

  &:after {
    content: "";
    border-top: 4px solid $purple;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
    width: 0;
    height: 0;
    margin-left: 11px;
    z-index: 1;
    position: absolute;
    right: 13px;
    top: 13px;

    @include media("<desktop") {
      border-top-color: $white;
    }
  }

  &[aria-expanded="true"] {
    background: $purple;
    @include media("<desktop") {
      display: none;
    }

    label {
      color: $white !important;
    }

    .value {
      transform: none;
      margin: 0;
    }

    .value_box {
      color: $white;
      background: $purple !important;
    }

    > div {
      span {
        color: $white !important;
      }
    }

    &:after {
      border-top: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-bottom: 4px solid $white;
    }
  }

  label {
    pointer-events: none;
    transform: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $purple;
    padding: 5px 0 0 16px;
    cursor: pointer;

    @include media("<desktop") {
      color: $white;
      font-size: 12px;
    }

    &[data-shrink="true"] {
      color: $purple;
      transform: none;
    }

    + div {
      position: static !important;
    }
  }

  .value {
    transform: none;
    margin: 0;
  }

  .value_box {
    position: relative !important;
    z-index: 1;
    color: $purple;
    width: 100%;
    background: $white !important;
    pointer-events: none;
    display: flex !important;
  }

  .value_length {
    margin: 0;
  }

  > div {
    > div {
      padding: 0 !important;
      flex-wrap: nowrap;
      display: flex;

      > div {
        padding: 0 !important;
        font: 14px/20px $base-font-sans-serif;
        height: 100% !important;
        box-shadow: none;
        margin: 0 !important;
        border: none !important;
        background: none !important;
        flex-wrap: nowrap;
        align-items: center;
        position: static !important;
        max-width: 131px;
        display: none;
        cursor: pointer;

        &:first-child {
          display: block;
        }

        &:after {
          display: none;
        }
      }
    }

    &:after {
      display: none;
    }

    > span {
      margin: 0;
      padding: 0;
    }

    span {
      color: $purple !important;
      box-shadow: none;
      padding: 0 !important;
      margin: 0 !important;
      border: none !important;
      background: none !important;
      height: auto !important;
      margin-left: 2px !important;

      &:after {
        display: none;
      }
    }

    svg {
      display: none;
    }

    input {
      width: 100% !important;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      color: $white;
      border: none !important;
      background: none !important;
      font: 14px/20px $base-font-sans-serif;
      border-radius: 0;
      cursor: pointer;
      caret-color: transparent;
    }

    fieldset {
      border: none !important;
    }
  }
}

.autocomplete_selected {
  background: $purple;

  .value_box {
    background: $purple !important;
    color: $white;
  }

  @include media("<desktop") {
    background: #ffff;
    min-width: 100px;
    .value_box {
      background: $white !important;
      color: $purple;
    }
  }

  &:after {
    border-top-color: $white;
    @include media("<desktop") {
      border-top-color: $purple;
    }
  }
  label {
    @include media("<desktop") {
      color: $purple;
    }

    &[data-shrink="true"] {
      display: none;
    }
  }
}

.TextField {
  width: 100% !important;
  max-width: 131px;
  height: 100% !important;
  position: static !important;
  cursor: pointer;
}

.popper {
  position: absolute;
  left: 0;
  top: 100%;
  width: 331px;
  padding: 12px 0 0;

  @include media("<desktop") {
    width: 100%;
    padding: 0px;
    height: 300px;
    position: initial;
  }
}

.paper {
  background: $white;
  border: 1px solid $border-input-color;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  padding: 24px 24px 8px;

  @include media("<tablet") {
    height: 100%;
  }
  @include media(">=tablet", "<desktop") {
    height: 100%;
    padding: 20px 100px;
  }

  &.pb {
    ul {
      padding: 0 !important;
    }
  }

  ul {
    padding: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    box-shadow: none !important;

    li {
      font-weight: 500;
      color: $black !important;
      padding: 10px 16px !important;
      border: 1px solid $border-button-color !important;
      box-shadow: none;
      margin: 0 8px 8px 0 !important;

      &[aria-selected="true"] {
        color: $white !important;
        background: $black;
      }
    }
  }

  span,
  button {
    width: 0;
    padding: 0;
    margin: 0 !important;
  }

  svg {
    width: 0;
  }

  .btn_box {
    padding: 20px 24px 0;
    width: 100%;
  }

  .btn_apply {
    background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
    border-radius: 31px;
    font-size: 16px;
    line-height: 22px;
    color: $white;
    padding: 13px;
    margin: 0 0 7px;
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .btn_clear {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $gray;
    padding: 13px;
    background: none;
    border: none;
    width: 100%;
    box-shadow: none;
    display: flex;
    justify-content: center;
  }

  .list_code {
    display: flex;
    flex-flow: wrap;
    .value_code {
      font-weight: 500;
      color: $black;
      padding: 10px 16px;
      border: 1px solid $border-button-color;
      box-shadow: none;
      margin: 0 8px 8px 0;
      border-radius: 22px;
    }

    .value_code_select {
      color: $white !important;
      background: $black;
    }
  }
}
