@import "../../../../theme/base/variables";

.heading_box {
  display: flex;
  align-items: flex-start;
  border-radius: 14px;
  margin: 0 0 12px;
  padding: 16px 16px 20px 20px;
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.05);

  .logo {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
  }

  .status_box {
    max-width: 80px;
    margin-left: auto;
  }

  .status {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    padding: 4px 6px;
    border-radius: 5px;
  }
}

.name_address {
  color: $gray;
  font-size: 12px;
  line-height: 16px;
  padding-right: 10px;

  h4 {
    font-weight: 500;
    margin: 0 0 4px;
  }

  address {
    font-style: normal;
  }
}