@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';

.container_mobile {
  height: 100vh;
}

.container_top {
  @include media('<desktop') {
    height: 90vh;
    overflow: auto;
    display: block !important;
  }
}
.customer_columns {
  display: flex;
  justify-content: space-between;

  @include media('<desktop') {
    position: relative;

    height: 100%;
    min-height: calc(100vh - 83px);
    display: block;
    width: 100%;
  }
}

.col {
  width: calc(50% - 10px);

  @include media('<desktop') {
    width: 100%;
  }
  .btn_box {
    padding: 0 40px;
  }

  button.purple_btn {
    width: 100%;
    height: 52px;
    font-weight: 600;

    &:disabled {
      opacity: 0.4;
    }
  }
}

.column_block {
  border: 1px solid $border-button-color;
  background: $white;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  padding: 32px 40px;
  min-height: 248px;
  margin: 0 0 24px;

  @include media('<desktop') {
    padding: 11px 20px;
    box-shadow: none;
    border: none;
    min-height: 100%;
    margin: 0px;
  }

  h2 {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin: 0 0 20px;

    @include media('<desktop') {
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 16px;
    }
  }

  .heading {
    padding: 20px 24px;
    box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);
    border-radius: 8px;
    margin: -32px -40px 0;

    @include media('<desktop') {
      margin: 0px;
      border: 1px solid #fafafa;
      box-sizing: border-box;
      box-shadow: 0px 4px 20px rgba(234, 234, 234, 0.75);
      border-radius: 0px;
      border-top-left-radius: 14px;
      border-top-right-radius: 14px;
    }

    h2 {
      margin: 0;
      color: $purple;
    }
  }
}

.info_text {
  color: $purple;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 20px;
  background: $light-purple4;
  border-radius: 8px;
  margin: 0 0 32px;

  p {
    margin: 0;
  }
}

.error_day_off {
  text-align: center;
  color: #e4597b;
  font-size: 14px;
}

.header_booking_mobile {
  text-align: center;
  position: relative;
  padding: 20px 0px;
  border-bottom: 1px solid #edeeef;

  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f1e20;
  }
}

.header_icon {
  position: absolute;
  top: 18px;
  left: 23px;
}

.col_bottom {
  .btn_box {
    padding: 32px 20px;
  }

  button.purple_btn {
    width: 100%;
    height: 52px;
    font-weight: 600;

    &:disabled {
      opacity: 0.4;
    }
  }
}

.btn_box_mobile {
  position: relative;
  height: 10vh;
  width: 100%;
  padding: 0px 20px;

  button {
    background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
    border: none;
    border-radius: 32px;
    padding: 20px 24px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
  }
}

button.btn_select {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 14px 20px;

  &:disabled {
    opacity: 0.4;
  }
}

.wrapper_text_select_client {
  background-color: $light-purple4;
  padding: 16px 24px;
  border-radius: 8px;
  margin-bottom: 32px;
  .text_select_client {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: $purple;
  }
}
