@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.container {
  span.colored {
    color: $purple;
  }
}

.reviews_block {
  font-size: 16px;

  h1 {
    margin-bottom: 32px;
    @include media("<desktop") {
      display: none
    }
  }

  section {
    padding: 0 24px 20px;
    border-bottom: 1px solid $border-button-color;
    margin-bottom: 16px;

    @include media("<desktop") {
      padding: 20px 20px;
      background: #FFFFFF;
      box-shadow: 0px 4px 20px rgba(234, 234, 234, 0.75);
      border-radius: 14px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  button {
    height: 44px;
    width: 181px;
    padding: 10px 20px !important;
    font-size: 18px;

    i {
      font-size: 12px;
    }
  }
}

