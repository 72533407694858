@import '../../../../theme/base/variables';
@import '../../../../theme//vendors/include-media';

.service_section {
  padding: 0 40px;
  margin: 0 -24px;
  box-shadow: 0 -4px 5px rgba(122, 122, 122, 0.02);

  .main_accordion {
    margin: 0;
    box-shadow: none;
    border-radius: 0;
  }

  .accordion_title {
    padding: 0;
    font-weight: 500;
    border-bottom: 1px solid $border-button-color;

    div {
      margin: 0;
    }
  }

  .accordionDetails {
    display: block;
    padding: 8px 0 0;
  }
}

.cost {
  color: $purple;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
}

.btn_box {
  text-align: center;
  margin-top: 24px;
  @include media('<desktop') {
    margin: 16px 0px;
  }
}

.btn_dialog {
  margin: 0 -40px;
  padding: 24px 24px 0;

  @include media('<desktop') {
    margin: 0px;
    width: 100%;
    padding: 10px 24px;
  }

  button {
    width: 100%;
    height: 52px;
    font-weight: 600;

    @include media('<desktop') {
      height: 100%;
    }
  }
}

.packagesWrap {
  .packagesTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .divider {
      height: 1px;
      background-color: #1f1e20;
      width: 100%;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 32px;
    }
  }
  .packages > div {
    border-top: none;
  }
  .packages .divider2:not(:first-child) {
    border-top: 1px solid #EDEEEF;
    margin-top: 16px;
  }
}
