@import '../../theme/vendors/include-media';

.root {
  position: relative;
  padding-top: 96px;
  @include media('<desktop') {
    padding-top: 50px;
  }
}

.btn_booking {
  position: fixed;
  bottom: 0px;
  z-index: 100;
  margin: auto;
  display: flex;
  justify-content: center;
  padding: 10px 20px;
  width: 100%;
  background-color: #fff;

  button {
    padding: 15px 20px;
    background: #7f2fea;
    border: none;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: #ffffff;

    width: 100%;
  }
}
