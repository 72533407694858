@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.search_results_section {
  background: $white;
  padding: 0;
  height: 100%;
  justify-content: space-between;
  position: relative;
  z-index: 1;
}

.search_results_section_active {
  position: static;
}
.search_results_section_active_map {
  display: none;
}

.heading {
  margin-bottom: 24px;
  padding: 32px 80px 0;
  
  @include media("<desktop") {
    padding-top: 20px;
    padding-left: 20px;
  }

  &.heading_active {
    padding: 32px 40px 0;

    @include media("<desktop") {
      display: none;
    }

    + div {
      > div {
        &:first-child {
          padding: 0 !important;
        }
      }
    }
  }

  h1 {
    margin: 0;
    color: $black;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;

    @include media("<desktop") {
      font-size: 18px;
      line-height: 22px;
      font-weight: 500;
    }
    span {
      color: $purple;
    }
  }
}

.search_box {
  background: $white;
  margin: 0 -30px 8px !important;
  position: relative;
  z-index: 1;
  padding: 0 60px 32px;

  @include media("<desktop") {
    padding: 8px 16px 8px 16px;
    margin: 0 !important;
    background: linear-gradient(323.63deg, #651fc1 0%, #631dbf 100%);
    width: 100vw;
    margin-top: -1px !important;
  }
  .sticky_holder {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    @include media("<desktop") {
      overflow-y: auto;
      scrollbar-width: none;
    }
  }
  .sticky_holder::-webkit-scrollbar {
    display: none;
  }
  &.search_box_active {
    padding: 0 60px 32px;
    margin-bottom: 0 !important;
    position: static !important;
    width: auto !important;
    box-shadow: 0 21px 20px -21px rgba(122, 122, 122, 0.1);
  }
  
  &.search_box_fixed {
    position: fixed;
    box-shadow: 0 5px 10px 8px rgba(122, 122, 122, 0.05);
    padding: 16px 80px;
    border-top: 1px solid $border-button-color;
    z-index: 1100;
    @include media("<desktop") {
      padding: 8px 16px 8px 16px !important;
      box-shadow: none !important;
      top: 64px !important
    }
  }
}

.root {
  position: relative;
  padding-top: 96px;
  @include media("<desktop") {
    padding-top: 64px;
  }
}

.scroll_box {
  padding: 0 !important;
  background: $white;
  margin-right: -47.36% !important;

  &.scroll_box_shift {
    width: 52.64%;
    margin-right: 0 !important;
    max-height: 928px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  @include media("<desktop") {
    position: relative;
  }
}

.scroll_box_holder {
  position: relative;
}

.appBar {
  position: static !important;
  right: 0;
  top: 48px !important;
  color: $black !important;
  justify-content: flex-end;
  height: auto !important;
  background: $white !important;
  box-shadow: none !important;

  &.appBar_active {
    margin: 0 !important;
    width: auto !important;
  }

  > div {
    min-height: 0;
    padding: 0 !important;
    width: 100%;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    z-index: 2;
    @include media("<desktop") {
      flex-direction: column-reverse
    }
  }

  button {
    color: $black;
    padding: 6px 16px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    border-radius: 22px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);

    i {
      margin-right: 10px;
    }
  }
}

.drawer {
  position: relative;
  width: 47.36% !important;

  > div {
    box-shadow: none !important;
    position: static !important;
    background: none !important;
    width: 100% !important;
    transition : none !important;
    overflow: hidden;
    height: 100vh !important;
    overflow-y: hidden !important;

    iframe {
      width: 100%;
    }
  }
}
.drawer_mobile {
  width: 100% !important;
  position: absolute;
  height: calc(100% + 110px ) !important;
  top: 105px;
}
.drawerHeader {
  min-height: 0 !important;
  position: absolute;
  left: 32px;
  top: 32px;
  padding: 0 !important;
  z-index: 2;
  @include media("<desktop") {
    z-index: 1;
    left: 16px;
    top: 16px;
  }

  button {
    box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1), 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    width: 48px;
    height: 48px;
    padding: 0;
    background: $white;

    span>svg {
      @include media("<desktop") {
        width: 50%
      }
    }

    @include media("<desktop") {
      width: 30px;
      height: 30px;
    }

    &:hover {
      background: $white;
    }
  }
}

.showMapBtn {
  position: fixed;
  z-index: 9;
  bottom: 25px;
  margin: auto;
  text-align: center;
  width: 100%;
}

.showMapBtnFixed {
  position: fixed !important;
  bottom: 25px;
}
.mapBtn {
  width: 83px;
  height: 44px;
  margin: auto;
  background: #1F1E20;
  // box-shadow: 0px 4px 20px rgba(169, 168, 192, 0.25);
  border-radius: 39px;
  color: #fff;
  display:flex;
  align-items:center;
  justify-content:center;
  span {
    margin-left: 8px;
  }
}

.searchResultMap {
  padding: 20px;
}

.searchResultMapMobile {
  position: fixed;
  width: 100%;
  z-index: 3;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 16px 0px 32px 0px;
  margin: 0;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  background: #FFFFFF;
  border-radius: 14px 14px 0px 0px;
  box-shadow: 0px -4px 20px rgba(147, 147, 150, 0.39);
  span {
    margin-right: 10px;
  }
}
.hidden {
  visibility: hidden;
}

.show {
  visibility: initial;
}

.sliderMobile {
  position: fixed !important;
  height: initial;
  width: 100%;
  z-index: 3;
  bottom: 70px;
  display: flex;
  flex-direction:row;
  flex-wrap: wrap;
  padding: 0 20px;
  margin: 0;

  &.horizontal {
    margin: 0;
    padding: 0px;

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      max-width: unset;

      // always show the tags in horizontal mode
      > div > div:last-child {
        opacity: 1;
      }

      + div {
        border-top: 1px solid $border-button-color;
      }

      > div {
        font-size: 16px;
        line-height: 20px;

        &:first-child {
          width: 220px;
          height: 161px;
          margin-right: 32px;
          flex-shrink: 0;

          img {
            height: 100%;
          }
        }
      }
    }
  }
}
.list_horizontal_display {
  div {
    display: inline-block
  }
}


