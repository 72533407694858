.msg-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
}

.small-msg {
  font-size: 10px;
  margin: 0;
  line-height: 12px;
}
