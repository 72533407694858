@import "../../../../theme/base/variables";

.heading_box {
  display: flex;
  align-items: flex-start;
  padding: 20px 24px;
  border-bottom: 1px solid $border-button-color;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  .logo {
    width: 40px;
    height: 40px;
    margin: 0 16px 0 0;
  }
  .edit_box {
    max-width: 80px;
    margin-left: auto;
    color: $purple;
    button {
      margin: 0;
      min-width: 0;
    }
  }
}

.name_address {
  color: $gray;
  font-size: 12px;
  line-height: 16px;
  padding-right: 10px;

  h4 {
    font-weight: 500;
    margin: 0 0 4px;
  }

  address {
    font-style: normal;
  }
}

.service_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $black;
  padding: 20px 24px;
  border-bottom: 1px solid $border-button-color;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  time {
    color: $purple;
    font-weight: normal;
  }
}

.subtotal {
  color: $black;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 24px;
  border-bottom: 1px solid $border-button-color;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  .row {
    display: flex;
    justify-content: space-between;
    + .row {
      padding-top: 16px;
    }
  }
  .cost,
  .cost_large {
    color: $purple;
  }
  .cost_large {
    font-size: 18px;
  }
}

.total {
  color: $black;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  padding: 20px 24px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  .row {
    display: flex;
    justify-content: space-between;
    + .row {
      padding-top: 16px;
    }
  }
  .cost,
  .cost_large {
    color: $purple;
  }
  .cost_large {
    font-size: 18px;
  }
}

.sub_row {
  padding: 0 24px 20px 24px;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  label {
    color: $gray;
    cursor: pointer;
  }
  .error svg {
    fill: red;
  }
}

.confirm {
  padding: 0 24px 20px;
  .btn_confirm {
    width: 100%;
  }
}

.iconLoading {
  width: 30px !important;
  height: 30px !important;
  color: #ffff !important
}
