@import "../../base/variables";

.react-daterange-picker__wrapper {
  border: none;
  color: $gray;
  height: 40px;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 11px 10px;
}

.react-daterange-picker__inputGroup {
  min-width: 0;
}

.react-daterange-picker__clear-button.react-daterange-picker__button,
.react-daterange-picker__calendar-button.react-daterange-picker__button,
.react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar {
  background: $white;
  border: 1px solid $border-button-color;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
  width: 343px;

  abbr[title] {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;
    font-size: 12px;
    line-height: 16px;
    font-weight: normal;
    color: $black;
    text-transform: none;
  }
}

.react-calendar__month-view__weekdays {
  padding: 0 0 13px;
  border-bottom: 1px solid $light-gray !important;
}

.react-calendar__month-view__weekdays__weekday {
  padding: 0;
}

.react-calendar__tile {
  color: $gray;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  z-index: 1;
  border-radius: 0;
  background: none !important;

  &:after {
    content: '';
    width: 32px;
    height: 32px;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }

  &.react-calendar__century-view__decades__decade,
  &.react-calendar__decade-view__years__year,
  &.react-calendar__year-view__months__month {
    &:after {
      display: none;
    }
  }
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {

  &:not(.react-calendar__tile--active) {
    color: $purple;

    &:after {
      background: $light-purple;
    }
  }
}

.react-calendar__tile--hasActive,
.react-calendar__tile.react-calendar__tile--now {
  color: $white;

  &:after {
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
  }

  &:hover {
    color: $white !important;

    &:after {
      background: linear-gradient(323.63deg, #5B13B7 0%, #8243DF 100%) !important;
    }
  }
}

.react-calendar__tile--active {
  color: $white;

  &:after {
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
  }

  &.react-calendar__tile--range.react-calendar__tile--rangeStart,
  &.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    &:before {
      content: '';
      transform: translateY(-50%);
      position: absolute;
      height: 32px;
      top: 50%;
      left: 50%;
      right: 0;
      background: $light-purple;
      z-index: -1;
    }
  }

  &.react-calendar__tile--range.react-calendar__tile--rangeStart.react-calendar__tile--rangeEnd {
    &:before {
      display: none;
    }
  }

  &.react-calendar__tile--range.react-calendar__tile--rangeEnd {
    color: $white;
    position: relative;

    &:after {
      background: linear-gradient(323.63deg, #5B13B7 0%, #8243DF 100%) !important;
    }
    &:before {
      left: 0;
      right: 50%;
    }
  }

  + .react-calendar__tile--active {
      color: $purple;

    &:not(.react-calendar__tile--range.react-calendar__tile--rangeEnd) {
      &:before {
        display: none;
      }
      &:after {
        transform: translate(0, -50%);
        height: 32px;
        width: auto;
        border-radius: 0;
        top: 50%;
        left: 0;
        right: 0;
        background: $light-purple;
      }
    }
  }
}

.react-calendar__navigation__label {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: $black;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background: none;
}

.react-calendar__navigation {
  height: auto;
}

.react-calendar__navigation__arrow.react-calendar__navigation__prev-button,
.react-calendar__navigation__arrow.react-calendar__navigation__next-button {
  font-size: 30px;
  padding: 25px 20px;
}