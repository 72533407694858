.wrapper-content-about-us {
  max-width: 1440px;
  margin: auto;
  padding: 0 80px;

  @media (max-width: 767px) {
    padding: 0 24px;
  }

  .content-about-us {
    display: flex;
    margin-bottom: 40px;
    .wrapper-img-about-us {
      width: 33.333%;
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }

      @media (max-width: 1023px) {
        display: none;
      }
    }
    .about-us-content {
      width: 66.666%;
      padding-left: 48px;

      @media (max-width: 1023px) {
        width: 100%;
        padding-left: 0px;
      }

      h3 {
        font-size: 32px;
        font-weight: 500;
        color: #1f1e20;
        line-height: 40px;
        margin-bottom: 8px;
      }
      p {
        font-size: 18px;
        font-weight: 400;
        color: #7e849b;
        line-height: 24px;
        margin-bottom: 20px;
      }

      span {
        font-size: 19px;
        font-weight: 600;
      }

      .text-second {
        margin-bottom: 4px;
      }

      @media (max-width: 767px) {
        h3 {
          font-size: 24px;
          line-height: 28px;
        }
        p {
          font-size: 14px;
          line-height: 20px;
          text-align: justify;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }
}
