@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.container {
  min-height: 300px;
}

.search_panel {
  width: 100%;
  height: 48px;
  padding-left: 21px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-radius: 40px !important;
  background: $white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid transparent;
  transition: all .3s ease-in-out;

  button.search_btn {
    border: none;
    padding: 0;
    height: auto;
    margin: 0;
    background: none;
    border-radius: 0;
  }
}

.paper {
  margin: 0 -24px;
  min-height: 194px;
  border: none;
  margin-top: 8px;
  font: 500 14px/20px $base-font-sans-serif;
  max-height: 244px;
  overflow: auto;
  @include media("<desktop") {
    margin: 32px;
  }

  > ul {
    overflow: visible !important;
  }

  ul {
    border: none !important;
    box-shadow: none !important;
    padding: 16px 0 0 !important;

    li {
      width: 100% !important;
      height: 64px !important;
      margin: 0 !important;
      padding: 11px 20px 9px !important;
      background: $white;
      color: $gray;
      border: none !important;
      border-radius: 0 !important;
      border-bottom: 1px solid $light-gray !important;
      box-shadow: none;
    }

    li:last-child {
      border-bottom: none !important;
    }
  }
}


.search_btn {
  padding: 0 !important;
  color: $black !important;

  + div {
    width: 100%;
    margin: 0 0 0 10px;
    padding: 0 !important;

    > div {
      padding: 0 !important;
      border: none !important;
    }
  }

  svg {
    width: auto !important;
    margin: 0 !important;
    transition: fill .3s ease-in-out;
  }
}

.search_input {
  margin: 0;
  height: 48px !important;
  width: 100%;
  padding: 5px 11px !important;
  color: $black !important;
  border: none !important;

  label {
    display: none
  }

  input[type="search"] {
    background: none;
    padding: 0 !important;
    height: 48px;
    border: none;
    color: $black;
    font: 500 16px/20px $base-font-sans-serif;
    transition: color .3s ease-in-out;
  }

  fieldset {
    border: none;
    height: 48px;
    top: 0;
  }

  button {
    display: none;
  }
}