@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';

.info_section {
  margin-top: -96px;
  overflow: hidden;
}

.container_notification {
  max-width: 1024px;
  margin: auto;
  margin-bottom: 100px;
  padding: 0 40px;

  @include media('<tablet') {
    padding: 0 20px;
  }
  .margin_top_60_notification {
    margin-top: 60px;
  }
  .wrapper_render_notification {
    .wrapper_title_notification {
      margin-bottom: 32px;
      .title_notification {
        font-size: 32px;
        font-weight: 500;
        line-height: 40px;
        color: #1f1e20;

        @include media('<tablet') {
          font-weight: 500;
          font-size: 24px;
          line-height: 28px;
        }
      }
    }
  }

  .wrapper_show_more_notification {
    margin-top: 24px;
    display: flex;
    justify-content: center;
    .show_more_notification {
      color: $purple;
      font-size: 18px;
      font-weight: 600;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
