@import "./variables";
@import "../vendors/include-media";

.submit-btn-save-changes.MuiButton-root {
  background: $submit-button-color;
  border: none;
  border-radius: 30px;
  padding: 12px 32px;
  font-size: 14px;
  color: $white !important;
  position: relative;

  &:disabled {
    opacity: 0.4;
  }

  &.with-icon {
    padding: 13px 44px !important;
    font-size: 16px;
    line-height: 20px;
    font-weight: 500;
    background: $dark-purple-3;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid transparent !important;
    }

    &:before {
      font-family: "icomoon", sans-serif !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e929";
      position: absolute;
      left: 22px;
      top: 13px;
      font-size: 18px;
      line-height: 18px;
    }

    &:after {
      content: "";
      border-top: 6px solid $white;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      width: 0;
      height: 0;
      visibility: visible;
      opacity: 1;
      left: auto;
      bottom: auto;
      top: 21px;
      right: 20px;
      z-index: 1;
      @include media("<desktop") {
        display: none;
      }
    }
  }
}

.MuiButton-outlinedPrimary:hover {
  border: none !important;
}

.btn-holder {
  text-align: center;
}

button.btn-add {
  background: $white;
  border: 1px solid $light-gray;
  border-radius: 30px;
  padding: 14px 24px 14px 43px;
  font-size: 14px;
  color: $purple;
  position: relative;

  &:before,
  &:after {
    content: "";
    position: absolute;
    left: 30px;
    top: 18px;
    width: 1px;
    height: 13px;
    background: $purple;
  }

  &:after {
    width: 13px;
    height: 1px;
    left: 24px;
    top: 24px;
  }
}

button.white-btn {
  background: $white;
  border: 1px solid $light-gray;
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.15);
  border-radius: 48px;
  padding: 14px 24px;
  font-size: 14px;
  color: $purple;
  height: 48px;
  @include media("<desktop") {
    height: 40px;
    padding: 12px 24px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
  }

  &.with-plus {
    position: relative;
    padding-left: 44px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      left: 24px;
      top: 22px;
      width: 13px;
      height: 1px;
      background: $purple;
    }

    &:after {
      left: 30px;
      top: 16px;
      width: 1px;
      height: 13px;
    }
  }

  i {
    margin-right: 10px;
  }
}

button.white-btn-disable {
  opacity: 0.5;
}

.MuiButton-outlinedPrimary.white-btn:hover {
  border: 1px solid $light-gray !important;
}

button.purple-btn {
  background: $purple;
  background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
  border: none;
  border-radius: 32px;
  padding: 14px 24px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $white;
  height: 48px;

  i {
    margin-right: 10px;
  }
}

button.plus-btn {
  background: $white;
  border: 1px solid $light-gray;
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.15);
  border-radius: 50%;
  height: 48px;
  width: 48px;
  position: relative;
  text-indent: -9999px;
  overflow: hidden;
  padding: 0;
  min-width: 0;
  color: $purple;

  &:hover {
    background: $white;
  }

  &:before,
  &:after {
    content: "";
    width: 16px;
    height: 1px;
    background: $purple;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &:after {
    height: 16px;
    width: 1px;
  }

  i {
    margin-right: 10px;
  }
}

button.btn-text {
  padding: 0;
  background: none;
  border: none;
  box-shadow: none;
  color: inherit;
  border-radius: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  margin-top: 10px;
}

button.btn-network {
  color: $purple;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $border-button-color;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 14px;
}

button.btn-edit {
  color: $purple;
  width: 40px;
  height: 40px;
  padding: 0;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: $white;
  border: 1px solid $border-button-color;
  border-radius: 50%;
}

.box-sms-notification {
  .MuiSwitch-root {
    width: 52px;
    height: 32px;
    padding: 0;
  }

  .MuiSwitch-colorSecondary {
    margin: 2px 2px;
    padding: 0;
  }

  .MuiSwitch-thumb {
    width: 28px;
    height: 28px;
    background-color: #fff !important;
  }

  .MuiSwitch-track {
    border-radius: 24px;
  }

  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #8243DF;
    opacity: 1;
  }
}
