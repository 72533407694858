@import '../../../../theme/base/variables';

.flags {
  margin-right: 8px;

  .flag {
    border-radius: 5px;
    padding: 4px 10px;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
    font-weight: 500;
    margin: 0 4px 0 0;
  }

  .flag.NEW {
    background: #FFE4FE;
    color: #F14CEA;
  }

  .flag.PERCENT_OFF {
    background: rgba($orange, 0.2);
    color: $orange;
  }

  .flag.TRENDING {
    background: $light-violet;
    color: $dark-violet;
  }
}
