@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";


.container {
  font-size: 16px;
}

.info {
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  margin-bottom: 33px;

  .author_name {
    flex: 1;
  }

  .rating {
    display: block;
    align-items: flex-end;

    .current_rating{
      font-size: 32px;
      color: $black;
      @include media("<desktop") {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        padding-left: 4px;
      }
    }
  }



  .avatar {
    height: 54px;
    width: 54px;
    background: $shimmer;
    color: $white;
    margin-right: 25px;

    @include media("<desktop") {
      margin-right: 10px;
    }
  }

}


.text {
  display: block;
  max-width: 595px;

  @include media("<desktop") {
    display: block;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1F1E20;
    max-width: 100%;
  }
}

.header_bottom { 
  display: flex;
  align-items: center;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  > div {
    display: flex;
    align-items: center;
    padding-right: 16px;
    > svg {
      color: #8461F3;
    }
  }
}