.wrapper-btn-show {
  .MuiAccordionSummary-expandIcon {
    display: none;
  }

  @media only screen and (max-width: 966px) {
    .show-less-btn {
        transform: rotate(0deg);
    }
    .show-more-btn {
        transform: rotate(180deg);
    }
    .MuiAccordionSummary-expandIcon {
      display: block;
      padding: 0px;
      svg {
        font-size: 16px;
      }
    }
  }
}
