@import '../../theme/vendors/include-media';

.accordion {
  box-shadow: 0px 0px 20px #0000000d !important;
  margin: 8px 0px;
  border-radius: 0px !important;
  &:before {
    content: none !important;
  }
}

.accordionDetails {
  padding: 0px !important;
}
.accordionSummary {
  padding: 0px 20px !important;
  > div > p {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 22px;
    color: #1f1e20;
  }
}

.main_accordion {
  box-shadow: 0px 0px 20px #0000000d !important;
  margin-bottom: 10px;

  &:before {
    display: none;
  }
}
