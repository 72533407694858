@import "../../../../theme/base/variables";

.container {
  padding: 8px 16px 32px;

  label {
    font-size: 16px;
    line-height: 20px;
    padding: 0 0 8px;
  }

  h3 {
    margin-bottom: 8px !important;
  }
}

.description {
  color: $purple;
  font-size: 14px;
  line-height: 20px;
  padding: 16px 40px 16px 20px;
  background: $light-purple4;
  border-radius: 8px;
  margin: 16px 0 20px 0;

  p {
    margin: 0;
  }
}

.booking_summary {
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;

  .heading {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: $input-black;
    border-bottom: 1px solid #fafafa;
    padding: 20px 20px 16px;
    display: block;
  }
}
