@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.staff {
  color: $black;
  text-align: center;

  .img_box {
    overflow: hidden;
    width: 108px;
    height: 108px;
    border-radius: 50%;
    margin: 0 auto 16px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("<desktop") {
      width: 91px;
      height: 91px;
    }
  }
}

.name {
  display: block;
  color: $black;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  width: 108px;
  margin: 0 auto 8px;
}

.rating {
  display: block;
  font-size: 16px;
  line-height: 20px;

  i {
    color: $dark-violet;
  }
}