@import '../../../../theme/base/variables';
@import '../../../../theme/vendors/include-media';

.form_radio_btn_row {
  + .form_radio_btn_row {
    .form_radio_btn {
      border-top: 1px solid $border-button-color;
    }
  }

  .packages {
    font-size: 14px;
    font-weight: normal;
    color: $gray;
  }

  &:first-child {
    .cost {
      font-size: 20px;
      line-height: 24px;
    }
  }

  input[type='radio'] {
    display: none;

    &:checked + label {
      background: rgba($purple, 0.08);

      .check {
        opacity: 1;
        visibility: visible;
      }
    }

    &:disabled + label {
      opacity: 0.5;
    }
  }
}

.form_radio_btn {
  padding: 20px 24px;
  margin: 0 -40px;
  display: block;
  cursor: pointer;
  position: relative;

  @include media('<desktop') {
    margin: 0px;
  }

  &:first-child {
    .cost {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .check {
    position: absolute;
    right: 0;
    top: 0;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    opacity: 0;
    visibility: hidden;
    text-indent: -9999px;
    overflow: hidden;

    &:after {
      content: '';
      position: absolute;
      left: 7px;
      top: 7px;
      width: 12px;
      height: 6px;
      border-radius: 1px;
      border-left: 2px solid $purple;
      border-bottom: 2px solid $purple;
      transform: rotate(-45deg);
    }
  }
}

.row_holder {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include media('<desktop') {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  + .row_holder {
    margin-top: 10px;
  }

  .staffName {
    white-space: nowrap;
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &.row_total {
    font-size: 18px;

    .cost {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.cost {
  color: $purple;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  @include media('<desktop') {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

.user {
  color: $gray;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  @include media('<desktop') {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }

  span {
    color: $black;
  }

  .avatar {
    font-size: 14px;
    line-height: 20px;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    margin-top: -2px;
  }

  button {
    margin: 0 !important;
    color: $purple !important;
    font-weight: normal !important;
  }
}
