@import '../../../../theme/base/variables';
@import '../../../../theme/vendors/include-media';

.grid {
  @include media('<desktop') {
    padding: 30px 20px;
  }
}
.mapWrap {
  height: 334px;
  border-radius: 20px;
  overflow: hidden;
  max-width: 630px;
  position: relative;
  @include media('<desktop') {
    max-width: 100%;
  }
}

.title {
  font-weight: 500;
  font-size: 24px !important;
  line-height: 28px;

  @include media('<desktop') {
    margin: 0px !important;
  }
}

.distance {
  color: black;
}

.viewOnMap {
  border-radius: 22px !important;
  border: 1px solid #ededed !important;
  padding: 10px 20px !important;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05) !important;

  @include media('<desktop') {
    border: none !important;
    box-shadow: none !important;
  }
  span {
    color: $purple !important;

    @include media('<desktop') {
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.title_location {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media('<desktop') {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    padding-bottom: 16px;
  }
}
