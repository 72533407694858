@import "../../theme/base/variables";

.checkboxLabel {
  display: inline-block;
}

.forgot-psw-wrapper {
  padding-top: 12px;
  cursor: pointer;
  color: $purple;
  font-size: 16px;
}

.wrapper-password-sign-in {
  display: flex;
  flex-direction: column;
  .wrapper-input-password-sign-in {
    position: relative;
    .wrapper-icon-show-password {
      position: absolute;
      right: 8px;
      top: 28px;
    }
  }
}

.list-sign-in-with {
  // margin: 0 -32px;
  // display: flex;
  // gap: 24px;
  .wrapper-sign-in-with {
    width: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border: 1px solid $border-button-color;
    border-radius: 40px;

    .wrapper-img {
      display: flex;
      justify-items: center;
      margin-right: 8px;

      img {
        width: 17px;
        height: 17px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 12px;
      line-height: 18px;
      color: $black;
    }
  }
}

.wrapper-login-facebook {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  border: 1px solid $border-button-color;
  border-radius: 40px;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: $black;
  background-color: $white;
  img {
    margin-right: 8px;
  }
}
