@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.mt16 {
  margin-top: 16px;
  @include media("<desktop") {
    padding-left: 20px
  }
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 60px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 60px;
  margin: 0;
  @include media(">=tablet","<=desktop") {
    display: flex;
    padding: 0px;
    flex-wrap: wrap;
  }
  @include media("<tablet") {
    display: flex;
    flex-direction: column;
    padding: 0px;
  }

  > div {
    padding: 0 20px 32px;
  }

  &.horizontal {
    margin: 0;
    padding: 0 40px;

    > div {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 32px 0;
      max-width: unset;

      // always show the tags in horizontal mode
      > div > div:last-child {
        opacity: 1;
      }

      + div {
        border-top: 1px solid $border-button-color;
      }

      > div {
        font-size: 16px;
        line-height: 20px;

        &:first-child {
          width: 220px;
          height: 161px;
          margin-right: 32px;
          flex-shrink: 0;

          img {
            height: 161px;
          }
        }

        h2 {
          font-weight: 500;
          line-height: 28px;
          margin: 0 0 16px;
        }
      }
    }
  }
}