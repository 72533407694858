@import "../../../theme/base/variables";

.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px 100px;
}

.container_holder {
  max-width: 1064px;
  margin: 0 auto;
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 24px;

  h2 {
    margin: 0 0 24px;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 20px;

    h2 {
      margin: 0;
    }

    a {
      color: $purple;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.col {
  width: calc(50% - 65px);
}

.map {
  border-radius: 24px;
  overflow: hidden;
  margin: 0 0 2px;
}

.location {
  color: $black;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin: 0 0 15px;
}

.item_info {
  list-style: none;
  margin: 0;
  padding: 0 0 40px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: $black;

  li {
    margin: 0 0 20px;
  }

  a {
    color: $black;
    display: inline-flex;
    align-items: center;
  }

  .circle {
    width: 40px;
    height: 40px;
    margin-right: 20px;
    border: 1px solid $border-button-color;
    box-sizing: border-box;
    box-shadow: 0 4px 10px rgba(122, 122, 122, 0.1);
    border-radius: 50%;
    color: $purple;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;

    .icon_mail {
      font-size: 11px;
    }
  }
}