@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.rating {
  max-width: 413px;
  padding: 40px !important;
  border: 1px solid $light-gray;
  border-radius: 14px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);

  hr {
    border: 1px solid $border-button-color;
    margin: 0 -40px 24px -40px;
  }
}

.info {
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;

  @include media("<desktop") {
    margin-bottom: 0px;
  }
  .rating_range {
    display: block;
    align-items: flex-end;
    margin-right: 24px;

    .current_rating {
      font-size: 32px;
      color: $black;
    }
  }
}