@import "../../theme/base/variables";

.sub-theme-verification {
  font-size: 16px;
  color: $gray;
}

.sub-theme-verification-wrapper {
  padding-top: 20px;
  padding-bottom: 20px;
}

.user-email {
  font-weight: bold;
}

.verification-wrapper .MuiGrid-root.MuiGrid-item {
  padding: 0 16px 0 0 !important;

  &:last-child {
    padding: 0 !important;
  }
}

.verification-input-square {
  width: 54px;
  height: 54px;
}

.box-button-wrapper {
  width: 100%;
}

.send-again-btn {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 4px;
  button {
    padding: 0px;

    &:hover {
      background-color: #fff;
      text-decoration-line: underline;
    }
  }
}

.send-again-verification-wrapper {
  padding-top: 30px;
  padding-bottom: 10px;
}

button.sendAgainMsg {
  padding: 30px;
}
