@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';
.col2 {
  padding-top: 39px;

  @include media('>=1024px', '<1440px') {
    padding-left: 20px !important;
    padding-top: 10px !important;
  }
}

.col1 {
  @include media('>=1024px', '<1440px') {
    padding-right: 20px !important;
  }
}

.partnership_block {
  padding-top: 40px;
  padding-left: 129px;
  padding-right: 9999px;
  margin-top: 60px;
  margin-left: -129px;
  margin-right: -9999px;
  border-top: 8px solid $light-gray4;

  @include media('>=1024px', '<1440px') {
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
    margin-top: 60px;
    margin-left: 0px;
    margin-right: 0px;
    border-top: 8px solid #f3f3f6;
  }

  > div {
    > div {
      &:first-child {
        width: calc(100% - 284px);
        padding-left: 80px;
        @include media('>=1024px', '<1440px') {
          width: 100%;
        }

        h2 {
          font-size: 32px !important;
          line-height: 40px !important;
        }

        p {
          font-size: 14px !important;
          line-height: 20px !important;
        }
      }

      &:last-child {
        width: 284px;
      }
    }
  }
}

.container_profile {
  padding-right: 0 !important;
}

.visual {
  // position: absolute;
  width: 100%;
  height: 250px;
  z-index: 0;
  padding: 0px;
  margin-top: -22px;
  clip-path: ellipse(82% 61% at 50% 39%);
  background: linear-gradient(
    323.63deg,
    $gradient-start 0%,
    $gradient-end 100%
  );
  overflow: hidden;

  @include media('<desktop') {
    height: 250px;
    margin-top: 40px;
  }

  &:before {
    content: '';
    width: 115px;
    height: 115px;
    border-radius: 50%;
    position: absolute;
    top: 140px;
    right: -5px;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    opacity: 0.8;
  }

  .visual_holder {
    max-width: 1114px;
    margin: 0 auto;
    min-height: 627px;
    display: flex;

    @include media('<desktop') {
      display: none;
    }
  }
}

.container_mobile {
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.listMobile {
  width: 100%;
  li {
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(122, 122, 122, 0.1);
    border-radius: 8px;
    margin-bottom: 10px;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
}
.listItem {
  width: 100%;
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.img_profile {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding-top: 20px;
  img {
    width: 100px;
    height: 100px;
    color: #fff;
  }
  svg {
    width: 100px;
    height: 100px;
    color: #fff;
  }
  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    margin-top: 16px;
  }
}

.iconButton {
  span {
    svg {
      width: 21px;
      height: 21px;
      color: #1f1e20;
      opacity: 0.5;
    }
  }
}

.listText {
  span {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #1f1e20;
  }
}

.dialogTitle {
  width: 100%;
}
