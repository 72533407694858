@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";


.root {
  position : relative;
}
.container {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $white;
  z-index: 1;
  @include media("<tablet") {
    height: 100%
  }
}

.background_images {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;

  > span {
    position: absolute;
  }

  span.big_round {

    height: 83vw;
    width: 83vw;
    top: 50%;
    right: -50%;
    transform: translateY(-50%);
    border-radius: 50%;
    background: $light-violet;

    &:before {
      position: absolute;
      height: 8.2vw;
      width: 8.2vw;
      top: 2%;
      left: 31%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      background: $purple;
      content: '';
    }

    &:after {
      position: absolute;
      height: 5.7vw;
      width: 5.7vw;
      bottom: 10%;
      left: 13%;
      transform: translateX(-50%) translateY(-50%);
      border-radius: 50%;
      background: $purple;
      content: '';
    }
  }

  span.scissors {
    height: 18.7vw;
    width: 22.7vw;
    top: 0;
    left: 25%;
    background: url("../../Assets/images/success/scissors.svg") no-repeat;
    background-size: 100%;
  }

  span.hairdryer {
    height: 18.7vw;
    width: 22.7vw;
    bottom: -5%;
    left: 25%;
    background: url("../../Assets/images/success/hairdryer.svg") no-repeat;
    background-size: 100%;
  }

  span.pomade {
    height: 17.2vw;
    width: 11vw;
    top: 10%;
    right: 0;
    background: url("../../Assets/images/success/pomade.svg") no-repeat;
    background-size: 100%;
  }

  span.makeup_brush {
    height: 15vw;
    width: 10vw;
    bottom: 6%;
    right: 0;
    background: url("../../Assets/images/success/makeup_brush.svg") no-repeat;
    background-size: 100%;
  }

}


.container_holder {
  max-width: 1440px;
  min-height: 940px;
  width: 100%;
  padding: 203px 188px;
  z-index: 1;

  @include media("<desktop") {
    padding: 0px 20px;
    min-height: 100%;
    position: relative;
    max-width: 100%;
  }
}

.success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  min-height: 495px;
  font-size: 20px;

  @include media("<desktop") {
    justify-content: end;
    min-height: 250px;
  }

  h1 {
    font-size: 80px;
    font-weight: 600;
    color: $dark-purple;
    margin-bottom: 18px;
    text-align: center;
    @include media("<desktop") {
      font-weight: 500;
      font-size: 42px;
      line-height: 44px;
      text-align: center;
      width: 100%;
    }
  }

  span {
    display: block;
    font-weight: 500;
    margin-bottom: 40px;
    @include media("<desktop") {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
      width: 100%;
    }
  }

  button {
    padding: 16px 60px;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    color: $white;
    font-size: 14px;
    border: none;
  }
}

.info_description {
  @include media("<desktop") {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}

.card {
  background: $white;
  padding: 20px 0 !important;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  font-size: 14px;

  h3 {
    margin-bottom: 6px
  }

  h3.info {
    margin-bottom: 8px;
    @include media("<desktop") {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
    }
  }

  span.colored {
    color: $purple;
  }

  section {
    padding: 0 24px 20px;
    border-bottom: 1px solid $border-button-color;
    margin-bottom: 16px;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }
}

.header {
  display: flex;

  .logo {
    height: 50px;
    width: 50px;
    margin-right: 16px;
    border-radius: 50%;
    overflow: hidden;
    flex-shrink: 0;

    @include media("<desktop") {
      height: 39px;
      width: 39px;
    }

    img {
      height: 50px;
      width: 50px;
      object-fit: fill;
    }
  }
}

.total {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  h3 {
    @include media("<desktop") {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.buttonMobile {
  padding: 16px 60px;
  background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
  color: $white;
  font-size: 14px;
  border: none;
  width: 100%;
}

.gridButton {
  margin-top: 20px !important;
}