$base-font-sans-serif: Epilogue, Arial, "Helvetica Neue", Helvetica, sans-serif;
$border-input-color: #f1f2f3;
$border-button-color: #edeeef;
$alert-color: #e4597b;
$success-color: #39b18d;
$purple: #5b13b7;
$light-purple: #f0e9f9;
$light-purple2: #f3edfa;
$light-purple3: #9666d8;
$light-purple4: #f7f4fc;
$dark-purple: #8243df;
$dark-purple-2: #7532D1;
$dark-purple-3: #7F2FEA;
$submit-button-color: linear-gradient(to bottom, $purple 0%, $dark-purple 100%);
$white: #fff;
$gray: #7e849b;
$light-gray: #f3f3f7;
$light-gray2: #505050;
$light-gray3: #eff1f7;
$light-gray4: #f3f3f6;
$black: #1f1e20;
$input-black: #000;
$footer-color: #212121;
$disabled-color: rgb(155, 153, 153);
$disabled-bg-color: #f8f8fB;
$blue: #254aa5;
$blue-1:#2aace4;
$blue-2: #5552fc;
$light-blue: #97a1ff;
$light-blue-2: #ededff;
$light-blue-3: #e9f3fa;
$light-blue-4: #e1e8f5;
$shimmer:#8dc8ea;

$pink: #ff3364;
$light-pink: #ffeff6;
$dark-pink: #dc96b5;
$dark-violet:#8461f3;
$light-violet:#efeaff;
$green: #42bb90;
$light-green: #edfff9;
$orange: #f79e1b;
$light-orange: #fff3e1;
$light-orange-1: #ffebda;
$dark-orange: #f8923d;
$red: #cd376d;
$light-red: #e4597b;
$light-red2: #ffdbe4;
$light-red3: #fde7ef;
$yellow: #f8aa37;
$gradient-start: #651fc1;
$gradient-end: #631dbf;
$turquoise: #35c8e9;
$turquoise-light: #e1f4f9;

$border-button: 1px solid $border-button-color;
$border-radius: 32px;
$paper-border-radius: 14px;


// This is the default html and body font-size for the base rem value.
$rem-base: 16px;

// Body
$base-text-color: $gray !default;
$base-background-color: #fff;
$font-size-base: 18px !default;
$line-height-base: 1.3333333333333333 !default;
$base-font-family: $base-font-sans-serif !default;

$vertical-rhythm: ($line-height-base * 1em);

$base-min-width: 600px;

// Liks
$base-link-color: $purple;

// Buttons
$btn-color: $white !default;
$btn-background: $submit-button-color  !default;
$btn-padding: 14px 32px;
$btn-font-size: inherit !default;

// Forms
$form-element-padding: 14px 24px;
$form-element-border-color: $border-input-color;
$placeholder-color: $gray !default;

// Headers
$h1-font-size: 32px !default;
$h2-font-size: 24px !default;
$h3-font-size: 18px !default;
$h4-font-size: 14px !default;
$h5-font-size: 12px !default;
$h6-font-size: 10px !default;

$headings-font-family: inherit !default;
$headings-color: $black !default;


$animation-speed: 0.3s;


$breakpoints: (
  'xs-phone': 320px,
  'phone': 600px,
  'tablet': 768px,
  'desktop': 1024px,
  'widescreen': 1200px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);