@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 42px;

  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin: 0;
  }
}

.header_mobile {
  display: none
}

.allLink {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 45px;
  color: $black;

  span {
    display: block;
    font-size: 20px;
    margin-right: 16px;
    font-weight: 500;
  }
}

.disebal_btn {
  opacity: 0.5;
}

.buttons_block {
  display: flex;
  margin-right: 80px;

  .buttons_box {
    display: flex;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 48px;
    margin-left: 16px;
    background: $white;
    border: none;
    padding: 0;
    box-shadow: 0 4px 20px rgba(122, 122, 122, 0.15);
    border-radius: 50%;
    color: $purple;
    
    &:first-child {
      svg {
        margin-right: -4px;
      }
    }

    svg {
      width: 17px;
    }
  }
}

.staff_slider {
  background: #000;
}

.noStaff {
  @include media("<tablet") {
    padding: 20px
  }
}