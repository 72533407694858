.wrapper-create-password {
  display: flex;
  flex-direction: column;
  .wrapper-input-create-password {
    position: relative;
    .wrapper-icon-show-password {
      position: absolute;
      right: 8px;
      top: 28px;
    }
  }
}
