.wrapper_page_error {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .button_error {
    background: linear-gradient(180deg, #5b13b7 0, #8243df);
    border: none;
    border-radius: 14px;
    padding: 12px 32px;
    font-size: 14px;
    color: #fff !important;

    &:hover {
      cursor: pointer;
    }
  }
}
