@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";
.container {
  flex-grow: 1;
  position: relative;
  > div {
    @include media("<desktop") {
      flex-direction: column-reverse;
    }
  }
}
.info_block_head {
  background: #ffffff;
  box-shadow: 0px 4px 40px #0000000d;
  border-radius: 14px 14px 0px 0px;
  margin-bottom: 20px;
  padding: 30px 20px 10px 20px;
}

.info_block {
  display: inline-flex;
  flex-direction: column;
  padding-top: 32px;
  padding-left: 80px;
  margin-bottom: 24px;
  width: 100%;

  @include media(">=tablet", "<=widescreen") {
    padding-left: 0px !important;
    padding-top: 0px;
  }

  @include media("<tablet") {
    padding-left: 0px;
    padding-top: 0px;
  }

  h1 {
    font-size: 48px;
    margin-bottom: 8px;
  }

  .logo {
    height: 60px;
    width: 60px;
    border-radius: 30px;
    margin-bottom: 24px;
    overflow: hidden;

    @include media("<desktop") {
      margin-bottom: 0;
    }

    img {
      height: 60px;
      width: 60px;
      object-fit: fill;
    }
  }
}
.info_title {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-bottom: 10px;

  h1 {
    font-size: 24px;
    font-weight: 800;
    line-height: 32px;

    @include media("<desktop") {
      width: 70%;
      margin: 0px;
    }
  }
}

.backLink {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 32px;
  cursor: pointer;

  span {
    margin-left: 8px;
    margin-top: 2px;
    font-size: 16px;
    font-weight: 400;
  }
}

.backLinkMoBile {
  position: absolute;
  top: 20px;
  left: 20px;
  background-color: initial !important;
  padding: 10px 0px !important;
  z-index: 2;
  > svg {
    color: #ffff;
  }
}

.address {
  margin-bottom: 16px;
  @include media("<desktop") {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 0px;
  }
}

.info {
  margin-bottom: 30px !important;

  @include media("<desktop") {
    margin: 10px 0px;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }

  span {
    display: inline-flex;
    align-items: center;
    height: 20px;
    margin: 0 8px 8px 0;
    font-size: 18px;
    font-weight: 400;
    color: $black;

    @include media("<desktop") {
      margin: 0 8px 0px 0;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }

  i {
    font-size: 17px;
    margin: 0 8px;
    color: $dark-violet;
    @include media("<desktop") {
      margin: 0 8px 0px 0px;
    }
  }

  .votes {
    color: $gray;

    @include media("<desktop") {
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.flags {
  display: flex;
  flex-flow: wrap;
  padding: 0;
  font-size: 10px;
  margin-top: auto;
  margin-bottom: 24px;

  @include media("<desktop") {
    margin-top: 8px;
    margin-bottom: 8px;
  }

  > span span {
    padding: 6px 12px !important;
    font-size: 14px !important;
    line-height: 20px !important;
  }
}

button.book {
  height: 52px;
  width: 260px;
  border: none;
  background: $dark-purple;
  color: $white;
  font-size: 14px/1 !important;
  font-weight: 600;
}

button.book_disable {
  opacity: 0.5;
}

.origin {
  width: 400px;
  height: calc(100% - 100px);

  > div {
    scrollbar-width: none;
    -ms-overflow-style: none; /* IE and Edge */
  }
  > div::-webkit-scrollbar {
    display: none;
  }
}
