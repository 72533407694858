@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.partnership_content {
  display: flex;
  justify-content: space-around;

  @include media("<desktop") {
    display: block;
    position: relative;
  }

  &.row_reverse {
    justify-content: space-between;
    flex-direction: row-reverse;
    @include media("<desktop") {
      margin: 20px 0px;
    }
  }

  .partnership_info {
    width: 40%;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;

    @include media("<desktop") {
      width: 80%;
      margin: auto;
      position: relative;
      z-index: 2;
    }

    .partnership_title {
      color: $dark-violet;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      margin-bottom: 9px;

      @include media("<desktop") {
        font-weight: 500;
        font-size: 22px;
        line-height: 30px;
        width: 100%;
        text-align: center;
      }

      .partnership_subtitle {
        color: $black;
      }
    }

    .partnership_description {
      margin-bottom: 0px;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;

      @include media("<desktop") {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        text-align: center;
      }
    }

    .partnership_description_second {
      font-weight: 600;
      @include media("<900px") {
        width: 100%;
      }
    }

    .partnership_download {
      width: 100%;
      margin-top: 20px;
      @include media("<desktop") {
        justify-content: center;
        align-items: center;
        text-align: center;
      }

      .partnership_download_title {
        font-size: 18px;
        font-weight: 800;
        line-height: 24px;
        color: #7e849b;
      }
      .partnership_button {
        display: flex;
        gap: 18px;
        @include media("<desktop") {
          justify-content: center;
          align-items: center;
        }
      }
    }

    .partnership_btn {
      height: 56px;
      min-width: 213px;
      font-size: 18px;
      line-height: 24px;
      padding: 12px 20px;
      background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
      border-radius: 28px;
      color: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      @include media("<desktop") {
        margin: auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        height: 50px;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .partnership_box {
    width: 40%;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media("<desktop") {
      width: 80%;
      margin: auto;
    }
  }

  .wrapper_icon_mobile {
    margin-top: 16px;
    display: flex;
    gap: 16px;

    .button_find_out {
      padding: 16px 24px;
      background-color: #712ece;
      color: #fff;
      border-radius: 28px;
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
    }

    @include media("<900px") {
      width: 100%;
      justify-content: center;
    }
  }
}