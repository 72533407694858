@import '../../../../theme/base/variables';
@import '../../../../theme/vendors/include-media';

.container {
  max-width: 522px;
  padding: 40px;
  border: 1px solid $light-gray;
  border-radius: 14px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);

  @include media('<desktop') {
    border-radius: 0px;
    box-shadow: none;
    border: none;
    padding: 0px;
    max-width: 100%;
  }
}

.item {
  display: flex;
  margin-bottom: 16px;
  padding-bottom: 12px;
  border-bottom: 1px solid $light-gray;

  span {
    width: 84px;
    color: $purple;
  }

  .day_off {
    color: $gray;
  }

  &:first-child {
    padding-bottom: 20px;
    @include media('<desktop') {
      display: none;
    }
  }

  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;

    @include media('<desktop') {
      display: flex;
      padding-bottom: 12px;
      border-bottom: 1px solid $light-gray;
    }
  }
}

.detailMobile {
  padding: 20px;
}
