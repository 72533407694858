@import "../../../../theme/base/variables";

.booking_info {
  color: $gray;
  font-size: 14px;
  line-height: 20px;
  padding: 20px 24px;
  border-bottom: 1px solid $border-button-color;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.03);
  + .booking_info {
    border-top: 1px solid #fafafa;
  }
  p {
    margin: 0;
  }
  time {
    color: $black;
  }
  .heading {
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: block;
    margin: 0 0 6px;

    span {
      color: $purple;
    }
    .packages {
      color: $gray;
      font-size: 14px;
      font-weight: normal;
      position: relative;
      top: -2px
    }
  }
  
}
