.tab-panel-mobile {
  padding-top: 0px !important;
  padding-right: 0px !important;
  padding-bottom: 24px !important;
  display: flex;
  flex-direction: column;

  &::before {
    width: 0px !important;
  }
  .col1.col1-policy {
    width: 100% !important;
    padding-right: 0px;
    .MuiTabs-vertical {
      min-height: auto;
    }
    .tabs-block {
      .MuiTabs-flexContainerVertical {
        flex-direction: row;
        overflow-y: auto;
        padding-bottom: 4px;

        button.MuiTab-textColorInherit {
          font-size: 12px;
          color: #7e849b;
          width: auto;
          height: 36px;
          min-height: auto;
          padding: 8px 24px;
          border-radius: 8px;
          border: 1px solid rgba(122, 122, 122, 0.1);
          margin: 0 4px;
        }
      }
    }
  }
  .col2 {
    width: 100% !important;
    padding-left: 0px !important;
    margin-top: 24px;
    h1,
    h2,
    h3 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 12px;
      padding-top: 0px;
    }

    p,
    li,
    th,
    td {
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      margin-bottom: 12px;
    }
  }
}

.col2 {
  .olStyles {
    list-style: lower-alpha;
  }
  .paddingTop0 {
    padding-top: 0px;
  }
  .titleH3 {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
    margin-right: 8px;
    padding-top: 0px;
    color: #1f1e20;
  }
  .olStylesChildren {
    list-style: lower-roman;
  }
  .textItalic {
    text-decoration: underline;
  }
}
