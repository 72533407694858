@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.customer_columns {
  display: flex;
  justify-content: space-between;

  @include media("<desktop") {
    display: block;
  }
  .col {
    width: calc(50% - 10px);
    @include media("<desktop") {
      width: 100%;
    }
    .column_block {
      border: 1px solid $border-button-color;
      background: $white;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.03);
      border-radius: 8px;
      min-height: 248px;
      margin: 0 0 24px;
      h2 {
        margin: 0;
        color: $purple;
      }
      hr {
        height: 1px;
        background: $border-button-color;
        border: none;
        margin: 28px 0 32px;
      }
    }
    .column_left {
      padding: 32px 40px;
      .wrapper_text_column_left {
        margin-top: 8px;
        .text_column_left {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }
}
.header_booking_mobile {
  text-align: center;
  position: relative;
  padding: 20px 0px;
  border-bottom: 1px solid #edeeef;

  div {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #1f1e20;
  }
}
.header_icon {
  position: absolute;
  top: 18px;
  left: 23px;
}

.wrapper_discount_code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  background: $white;
  box-shadow: 0px 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;
  width: 100%;
  cursor: pointer;

  .title_discount_code {
    padding: 0px;
    flex: 1;
    justify-content: flex-start;
    padding: 16px 0px;

    &:hover {
      background-color: transparent;
    }

    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: $gray;
    }
    .title_discount_code_purple {
      color: $purple;
    }
  }

  .button_clear_discount {
    z-index: 10;
    padding: 0px;
    font-size: 18px;
  }
}

.wrapper_content_discount {
  .title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    color: $black;
    margin-bottom: 8px;
  }
  .description {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: $gray;
  }
  .hr_box {
    background-color: #edeeef;
    margin: 24px 0;
    height: 1px;
    width: 100%;
  }
  .wrapper_input {
    .title_input {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: $black;
      margin-bottom: 12px;
    }
    .content_input {
      input {
        width: 100%;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $black;
        padding: 18px 24px;

        &:focus-visible {
          outline: none;
        }
      }
    }
  }
}

.box_discount_cap {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: $purple;
  background-color: $light-purple4;
  border-radius: 8px;
  padding: 12px 16px;
  margin-top: 12px;
}
