@import "../../theme/base/variables";

.check_block {

  .radio_holder {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -30px;
    padding: 16px;

    li {
      width: 33.333%;
      padding: 0 10px 40px;
    }
  }

  .avatar_box {
    border-radius: 0;
    color: $black;
    display: flex;
    margin: 0 auto !important;

    span {
      &:last-child {
        span {
          color: $black;
          font-weight: 500;
        }
      }
    }
  }

  .rating {
    display: block;
    font-weight: normal !important;

    i {
      color: $dark-violet !important;
    }
  }
}