@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.filter_box {
  display: flex;
  align-items: center;
  position: relative;
  width: auto;

  &.filter_box_active {
    .clear_filters {
      position: static;
      margin-left: 12px !important;
      width: unset;
      white-space: nowrap;
    }
  }

  .clear_filters {
    color: $gray;
    padding: 0;
    border: none;
    background: none;
    font-size: 14px;
    line-height: 20px;
    box-shadow: none;
    position: absolute;
    top: -45px;
    right: 0;
    margin: 0 !important;
    @include media("<desktop") {
      width: 90px;
      color: $white;
      font-size: 12px;
    }
  }

  > * {
    + * {
      margin-left: 12px !important
    }
  }
}