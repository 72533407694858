@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.header_logo {
  width: 139px;
  height: 43px;
  display: block;
  position: relative;
  margin-right: 60px;

  @include media("<1400px") {
    margin-right: 20px;
  }

  .logo_img_scroll {
    opacity: 0;
    visibility: hidden;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out;
  }
}

.header_scroll {
  background: #fff !important;
}
.header_not_main {
  background: linear-gradient(323.63deg, #651fc1 0%, #631dbf 100%) !important;
}
.logo_img_not_main {
  visibility: hidden !important;
  opacity: 1;
}

div .header {
  height: 96px;
  background: none;
  padding: 25px 80px 28px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  transition: all 0.3s ease-in-out;

  &.disable_transition {
    transition: none !important;
  }

  @include media("<desktop") {
    padding: 0px 20px;
    height: 64px;
  }

  @include media("<1300px") {
    padding: 25px 16px 28px;
  }

  > div {
    display: flex;
    align-items: center;
  }

  button {
    font-size: 16px;
    line-height: 22px;
    color: $white;
    border: 1px solid $dark-purple-3;
    padding: 13px 24px;
    height: 48px;
    margin-left: 16px;
    background: $dark-purple-3;
    border-radius: 40px;
    position: relative;
    z-index: 1;
    transition: all 0.3s ease-in-out;

    @include media("<1440px") {
      white-space: nowrap;
    }

    @include media("<1200px") {
      padding: 13px 14px;
      margin-left: 6px;
    }

    &:after {
      content: "";
      position: absolute;
      left: -1px;
      top: -1px;
      right: -1px;
      bottom: -1px;
      z-index: -1;
      pointer-events: none;
      visibility: hidden;
      border-radius: 40px;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    }

    &:hover {
      &:after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .button_sign_up {
    color: $dark-purple-3;
    background: $white;

    &:hover {
      &:after {
        visibility: hidden;
      }
    }
  }
}
