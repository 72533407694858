@import '../../../../theme/vendors/include-media';

.card {
  width: 414px;
  height: 224px;
  padding: 15px 16px 33px 20px;
  background: #f7f4fc;
  border-radius: 14px;
  border: 1px solid #6722c3;
  margin-bottom: 16px;

  @include media('<desktop') {
    width: 100%;
  }
  &:last-child {
    margin-bottom: 32px;
  }
  .card_title {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    position: relative;
  }
  .card_number {
    font-size: 24px;
    color: #9666d8;
    list-style: none;
    display: flex;
    margin-bottom: 24px;
    padding: 0;
    li {
      margin-right: 26px;
      @include media('<desktop') {
        font-weight: normal;
        font-size: 18px;
        line-height: 22px;
      }
    }
  }
  .card_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #9666d8;
    margin-bottom: 20px;

    @include media('<desktop') {
      font-weight: 500;
      font-size: 12px;
      line-height: 14px;
    }
  }
  .btn_edit {
    padding: 2px;
    color: #7330cf;
    background: #f7f4fc;
    font-size: 14px;
    border: none;
    i {
      margin-right: 10px;
    }
  }
  .statusWrap {
    position: absolute;
    right: 0;

    span {
      font-size: 12px;
      padding: 8px 12px;
      color: #ffffff;
      border-radius: 22px;
    }
    .default {
      background-color: #7f2fea;
    }
    .invalid {
      color: #e4597b;
      background-color: #ffecf1;
      margin: 5px;
    }
  }
}
