@import "../../theme/base/variables";

.block_heading {
  color: $white;
  font-size: 18px;
  line-height: 24px;
  padding: 0 0 32px;

  h1 {
    color: $white;
    font-weight: 500;
    line-height: 40px;
  }

  p {
    margin: 0 0 10px;

    &:last-child {
      margin: 0;
    }
  }
}