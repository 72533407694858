@import "../../../../theme/vendors/include-media";
@import "../../../../theme/base/variables";
.services_box {
  width: 60%;
  flex-grow: 1;
  overflow: auto;
  margin-right: -25px;

  @include media("<desktop") {
    margin-right: 0px;
  }
  & > div {
    & > div {
      padding: 0;
    }
  }
  .services_box_holder {
    max-width: 817px;
  }
}

.wrapper_categories_box {
  width: 40%;
}

.categories_box {
  width: 100%;
  margin-right: 130px;
  @include media(">=tablet", "<=widescreen") {
    margin-right: 30px;
  }
}

.btn_list {
  width: 413px;
  border: none !important;
  background: #fff;
  padding: 18px 32px !important;
  font-size: 18px !important;
  color: #5b13b7 !important;
  &:hover {
    background: #fff !important;
    text-decoration: underline !important;
  }
}

.accordion {
  box-shadow: 0px 0px 20px #0000000d !important;
  margin: 8px 0px;
  border-radius: 0px !important;
  &:before {
    content: none !important;
  }
}

.accordionDetails {
  padding: 0px !important;
}
.accordionSummary {
  padding: 0px 20px !important;
  > div > p {
    font-weight: 500;
    font-size: 16px !important;
    line-height: 22px;
    color: #1f1e20;
  }
}

.packagesWrap {
  .packagesTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 32px !important;

    .divider {
      height: 1px;
      background-color: #1f1e20;
      width: 100%;
    }
    .title {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      margin: 0 32px;
      color: #1f1e20;
    }
  }
  .packages div:first-child {
    border-top: none;
  }
  .packages > div:not(:first-child) {
    border-top: 1px solid #EDEEEF;
  }
}
