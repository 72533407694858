@import "../../../../theme/base/variables";

.cancelCard {
  box-shadow: 0 4px 20px rgba(89, 89, 89, 0.1);
  border-radius: 14px;
  margin: 8px 16px 16px;
  padding: 16px;
  color: $black;
  font-size: 16px;

  p {
    margin-bottom: 8px;
  }

  ul {
    li {
      font-size: 14px;
      margin-bottom: 4px;
    }
  }
}
