@import "../../theme/vendors/include-media";

// forms
form,
fieldset {
  margin: 0;
  padding: 0;
  border-style: none;
}

input[type="text"],
input[type="tel"],
input[type="email"],
input[type="search"],
input[type="password"],
textarea {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  box-sizing: border-box;
  height: 56px;
  font: 14px/20px $base-font-sans-serif;
  border: 1px solid $form-element-border-color;
  padding: $form-element-padding;
  border-radius: 46px;
  background: $white;
  letter-spacing: normal;
  color: $black;
}

textarea {
  width: 100%;
  resize: vertical;
  vertical-align: top;
  border-radius: 14px;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 1px $form-element-border-color inset;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
}

select {
  -webkit-border-radius: 0;
  padding: 0 32px !important;
  height: 56px !important;
}

button,
input[type="button"],
input[type="reset"],
input[type="file"],
input[type="submit"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  cursor: pointer;
  font-weight: 500;
  padding: 18px 32px;
  font-family: $base-font-sans-serif !important;
  text-transform: none !important;
  border-radius: 30px;
}

.form {
  padding-top: 5px;
}

.MuiButton-root.submit-btn {
  background: $purple;
  background: $submit-button-color;
  border: none !important;
  border-radius: 30px;
  color: $white !important;
  padding: 15px 32px;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  height: 56px;
  box-shadow: none !important;
}

.MuiOutlinedInput-root {
  border-radius: 30px !important;
}

.verification-input-square .MuiOutlinedInput-root {
  border-radius: 14px !important;
}

.disabledBG {
  border-radius: 46px;
  background-color: $disabled-bg-color;
}

.disabledBG .MuiInputBase-root input {
  background-color: $disabled-bg-color;
  border: none !important;
  color: $gray;
}

.disabledBG fieldset {
  border: none;
}

.Mui-disabled {
  color: $disabled-color !important;
}

.Mui-checked {
  color: $purple !important;
}

.MuiSelect-select:focus {
  background: none !important;
}

.MuiOutlinedInput-notchedOutline {
  border-color: $form-element-border-color !important;
}

.textField .Mui-error fieldset.MuiOutlinedInput-notchedOutline,
.MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: $alert-color !important;
}

.verification-input-square input {
  text-align: center;
  padding: 10px;
  border: none;
  font-size: 20px;
  line-height: 22px;
  font-weight: 500;
  color: $input-black;
}

.MuiInputLabel-root.label {
  font-family: $base-font-sans-serif;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 12px;
  color: $black;
}

.grid-padding .MuiGrid-item {
  padding: 8px 0;

  &.MuiGrid-grid-sm-6 {
    &:nth-child(odd) {
      padding-right: 10px;
      padding-left: 0;

      @include media('<phone') {
        padding-right: 0;
      }
    }

    &:nth-child(even) {
      padding-left: 10px;
      padding-right: 0;

      @include media('<phone') {
        padding-left: 0;
      }
    }
  }
}

.input-wrapper-section {
  padding: 0 8px 24px;

  h3 {
    margin: 0 0 15px;
  }
}

.row-selection {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 24px 0 20px;

  + .row-selection {
    border-top: 1px solid $border-button-color;
  }

  .avatar-title {
    margin-right: 36px;
  }
}

.row-selection-holder {
  display: flex;
  align-items: center;
  padding-left: 36px;
  border-left: 1px solid $border-button-color;
}

.input-wrapper {
  padding: 0 0 24px;

  .input-wrapper-section & {
    border: 1px solid $border-button-color;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    margin: 0;
    width: 100%;
    padding: 8px 16px 16px;

    &.input-box-holder {
      padding: 8px 32px 20px;
    }
  }

  .MuiGrid-item {
    &:only-child {
      padding: 0;
    }
  }
}

.MuiDrawer-root {

  .MuiDrawer-paper {
    background: $white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border: none;
  }

  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    overflow: hidden;
    top: 96px;
    height: calc(100vh - 96px);
  }
}

//autocomplete

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] .MuiAutocomplete-endAdornment {
  display: none;
}

.MuiFilledInput-underline:before {
  display: none !important;
}

.MuiAutocomplete-tag {
  height: 36px !important;
  margin: 0 10px 8px 0 !important;
  border: 1px solid $form-element-border-color !important;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 22px !important;
  padding: 8px 13px 8px 20px !important;
  color: $gray !important;
  font: 14px/20px $base-font-sans-serif;
  position: relative;
  background: $white !important;

  svg {
    opacity: 0;
    position: absolute;
    right: 9px;
    top: 6px;
    z-index: 1;
    margin: 0;
  }

  span {
    margin-right: 20px;
  }
}

.MuiAutocomplete-tag:after {
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  right: 13px;
  top: 11px;
  content: "\e911";
  font-size: 12px;
  pointer-events: none;
}

.MuiChip-label {
  padding-left: 0 !important;
}

.MuiChip-outlined .MuiChip-deleteIcon {
  opacity: 0;
}

.MuiAutocomplete-listbox {
  display: flex;
  flex-wrap: wrap;
  background: $white;
  border: 1px solid $form-element-border-color !important;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  padding: 24px 24px 8px !important;

  li {
    width: auto !important;
    margin: 0 16px 16px 0 !important;
    height: 36px !important;
    border: 1px solid $form-element-border-color !important;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
    border-radius: 22px !important;
    padding: 8px 20px !important;
    color: $gray !important;
    font: 14px/20px $base-font-sans-serif;
  }
}

.MuiInput-underline:before,
.MuiInput-underline:after,
.MuiFilledInput-underline:after {
  display: none !important;
}

.makeStyles-formControl-21 {
  margin: 0 !important;
  min-width: 0 !important;
}

// phone select

.react-tel-input {

  .form-control {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    box-sizing: border-box;
    height: 56px;
    font: 14px/20px $base-font-sans-serif;
    border: 1px solid $form-element-border-color;
    padding: $form-element-padding;
    padding-left: 65px;
    padding-top: 16px;
    border-radius: 46px;
    background: $white;
    letter-spacing: normal;
    color: $black;
    width: 100%;
  }

  .flag-dropdown {
    background: none;
    border: none;
    border-radius: 46px 0 0 46px;

    &.open {
      background: none;
      border-radius: 46px 0 0 46px;

      .selected-flag {
        background: none;
        border-radius: 46px 0 0 46px;
      }
    }
  }

  .selected-flag {
    padding-left: 24px;
    border-radius: 46px 0 0 46px;

    &:hover,
    &:focus {
      background: none;
    }

    .arrow {
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 5px solid #555;
    }
  }
}

.MuiTypography-body1 {
  font-size: 14px !important;
}

.avatar-check {
  position: relative;
  width: 68px;
  border-radius: 50%;
  padding: 0 !important;
  text-align: center;
  color: $gray;
  font-size: 16px;
  line-height: 20px;
  margin: 0 !important;
  display: block;

  &.avatar-check-large {
    width: 108px;

    .MuiRadio-root {
      width: 108px;
      height: 108px;
    }

    .MuiFormControlLabel-label {

      .MuiAvatar-root {
        padding: 1px;
        width: 108px;
        height: 108px;
        margin: 0 auto 16px;
      }
    }
  }

  .MuiRadio-root {
    width: 68px;
    height: 68px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);

    @include media("<desktop") {
      width: 60px;
      height: 60px;
    }

    .MuiIconButton-label {
      position: absolute;
      right: 0;
      top: 0;
      padding: 0;
      width: 23px;
      height: 23px;
      border-radius: 50%;
      background: $dark-violet;
      opacity: 0;
      visibility: hidden;

      @include media("<desktop") {
        top: -2px;
        right: -8px;
      }

      &:after {
        content: '';
        position: absolute;
        left: 6px;
        top: 7px;
        width: 12px;
        height: 6px;
        border-radius: 1px;
        border-left: 2px solid $white;
        border-bottom: 2px solid $white;
        transform: rotate(-45deg);
      }

      svg,
      .makeStyles-icon-2 {
        opacity: 0;
        visibility: hidden;
      }
    }

    &.Mui-checked {
      z-index: 1;
      border: 2px solid $dark-violet;

      .MuiIconButton-label {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  .MuiFormControlLabel-label {
    padding: 0 !important;
    width: 100%;
    height: 100%;

    .MuiAvatar-root {
      padding: 1px;
      width: 68px;
      height: 68px;
      margin: 0 auto 16px;


    @include media("<desktop") {
      width: 60px;
      height: 60px;
    }
    }
  }

  .check-name {
    display: block;
    color: $gray;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    margin: 0 0 8px;

    @include media("<desktop") {
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.price-box {
  border: 1px solid $form-element-border-color;
  border-radius: 46px;
  background: $white;
  display: flex;

  &.price-box-revert {
    flex-direction: row-reverse;

    .select-formControl {
      &:after {
        right: auto;
        left: 0;
      }

      > div div {
        padding: 19px 24px 0 0 !important;
        text-align: right;

        &:after {
          left: 11px;
          right: auto !important;
        }
      }
    }
  }

  .select-formControl,
  input[type="text"] {
    border: none !important;
    background: none;
    border-radius: 0;
  }

  .select-formControl {
    width: 71px;
    flex-shrink: 0;

    span {
      display: block;
      font-size: 18px;
      padding: 17px 0 0 24px;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 10px;
      bottom: 10px;
      width: 1px;
      background: $border-button-color;
    }

    > div div {
      padding: 19px 0 0 24px !important;
      font-size: 18px;
      color: $black;

      &:after {
        right: 11px;
      }
    }
  }

  input[type="text"] {
    flex-grow: 1;
  }

  fieldset {
    border: none;
  }
}

.sub-text-select {
  display: block;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  padding: 8px 0 0;
}

.location {
  position: relative;
  margin: 0 0 20px;

  div {
    width: 100%;
  }

  &:after {
    content: '';
    position: absolute;
    left: 88px;
    top: 16px;
    bottom: 16px;
    width: 1px;
    background: $border-button-color;
  }

  .icon-location {
    position: absolute;
    left: 36px;
    top: 13px;
    color: $purple;
    font-size: 28px;
  }

  input {
    padding-left: 108px;
  }
}

.MuiFormControlLabel-root {
  color: $black;

  .MuiTypography-body1 {
    font-weight: 500;
  }
}

.input-box-holder {

  .input-box {
    padding: 0;

    input {
      border-radius: 14px;
      height: 40px;
    }

    + .input-box {
      margin-left: 21px;
    }

    &.input1 {
      width: 86px;
    }

    &.input2 {
      width: 127px;
    }

    &.input3 {
      width: 130px;
    }

    &.input4 {
      width: 178px;
    }

    .select-formControl {
      height: 40px;
      font-size: 12px;
      line-height: 16px;
      border-radius: 14px;

      > div {
        font-size: 12px;
        line-height: 16px;

        div {
          padding: 12px 16px !important;
          height: 40px;
          font-size: 12px;
          line-height: 16px;

          &:after {
            font-size: 14px;
            right: 12px;
            top: 12px;
          }
        }
      }
    }

    .price-box {
      .select-formControl {
        width: 51px;
      }
    }

    .MuiOutlinedInput-root {
      border-radius: 14px !important;
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="search"],
    input[type="password"],
    textarea {
      padding: 12px;
      height: 40px;
      font-size: 12px;
      line-height: 16px;
    }
  }

  .price-box {
    border-radius: 14px;
  }
}


.MuiList-root {
  li.disabled {
    display: none;
  }
}

.input-box-flex {
  display: flex;
}

.input-holder1 {
  width: 93px;
}

.input-holder2 {
  width: 175px;
}

.fieldset-radio {
  display: block !important;

  .legend {
    display: block;
    color: $gray !important;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 0 0 20px;
    position: relative;

    &:before {
      content: '';
      height: 1px;
      position: absolute;
      left: 0;
      top: 50%;
      right: 0;
      margin-top: -1px;
      background: $border-button-color;
    }

    span {
      padding: 0 16px;
      position: relative;
      background: $white;
      z-index: 1;
    }
  }

  .aria-label {
    flex-direction: row !important;
    margin: 0 -10px;
    padding: 0 0 9px;

    @include media("<desktop") {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 0px;
    }

    .MuiFormControlLabel-root {
      margin: 0 8px 15px;
      position: relative;
    }

    .Mui-disabled {
      opacity: 0.8;
    }

    .MuiRadio-root {
      background: $white;
      box-shadow: 0 4px 20px rgba(234, 234, 234, 0.75);
      border-radius: 8px;
      width: 99px;
      height: 39px;
      padding: 10px;
      font-size: 14px;
      line-height: 20px;

      + .MuiFormControlLabel-label {
        color: $gray;
        font-weight: normal;
      }

      &.Mui-checked {
        box-shadow: none;
        background: $light-purple2;

        + .MuiFormControlLabel-label {
          color: $purple;
        }
      }

      svg {
        opacity: 0;
        visibility: hidden;
      }
    }

    .MuiFormControlLabel-label {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      white-space: nowrap;
    }
  }
  div {
    @include media("<desktop") {
      padding: 20px
    }
  }
}