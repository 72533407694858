@import "../../../../theme/base/variables";

.note {
  margin-bottom: 24px;
}

.note_title,
.policy_title {
  font-size: 18px;
  color: $black;
  margin-bottom: 12px;
}

.note_description,
.policy_description {
  font-size: 14px;
  color: $gray;
  margin: 0;
}
