@import '../../../../theme/base/variables';
@import '../../../../theme/vendors/include-media';
.colored {
  color: $purple;
}

.schedule {
  color: $black;
}

.serviceName {
  @include media('<desktop') {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .packages {
    color: $gray;
    font-size: 14px;
    font-weight: normal;
    position: relative;
    top: -2px
  }
}

.serviceTime {
  @include media('<desktop') {
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
  }
}
