.user-marker-out {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  transform: translate(-50%, -50%);
  border: 16px solid rgba(132, 97, 243, 0.2);
  border-radius: 50%;
  position: relative;
}

.user-marker-out .user-marker-in {
  background-color: #8461f3;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
