@import '../../../../theme/vendors/include-media';

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.text {
  @include media("<desktop") {
    margin-bottom: 24px;
  }

  @include media('<tablet') {
    display: none;
  }
  margin-bottom: 40px;
  .title_box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-size: 32px;
      color: #1f1e20;
      margin-bottom: 12px;

      @include media("<desktop") {
        font-size: 24px;
      }
    }
  }
  .subtitle {
    font-size: 18px;
    color: #7e849b;

    @include media("<desktop") {
      font-size: 14px;
      line-height: 20px;
    }
  }
}

.form_container {
  flex-wrap: nowrap !important;
  @include media('<tablet') {
    display: block !important;
  }
  .left_box {
    margin: 0 67px 0 0;
    @include media('<tablet') {
      margin: 0px;
    }
  }
  .right_box {
    flex-grow: 1;
    .right_box_info {
      padding-bottom: 32px;
    }
    .birdth {
      display: flex;
      .date {
        margin-right: 19px;
      }
    }
  }
}

.btnSave {
  display: flex !important;
  margin: auto !important;
}
