@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.date_picker {
  margin: 0 0 26px !important;
  width: 100%;
  height: 56px;
  position: relative !important;
  background: $white;
  box-shadow: 0 4px 20px rgba(122, 122, 122, 0.1);
  border-radius: 8px;

  @include media("<desktop") {
    margin: 0 0 16px !important;
  }

  > label {
    display: none;

    + div {
      margin: 0 !important;
    }
  }

  input {
    width: 100%;
    border: none !important;
    border-radius: 0;

    + div {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      max-height: none;
      height: auto;
      margin: 0;
    }
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border-radius: 0;

    &:hover {
      background: none !important;
    }

    &:after {
      font-family: 'icomoon', sans-serif !important;
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: "\e907";
      position: absolute;
      right: 18px;
      top: 18px;
      font-size: 17px;
    }

    svg {
      display: none;
    }
  }
}