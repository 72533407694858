.stripe-payment-form {
  width: 100%;
  align-self: center;
}

.stripe-payment-form .label {
  font-weight: 400;
}
.stripe-payment-form .text-field input {
  height: 44px !important;
  padding: 12px;
}
.stripe-payment-form .text-field fieldset {
  border: 1px solid #df1b45;
}
.stripe-payment-form .input-wrapper {
  display: block;
}
.stripe-payment-form .alert-message {
  text-align: left;
  margin-bottom: 0;
  color: #df1b41;
  padding-top: 6px;
  font-weight: 400;
}

.wrapper-payment-element-modal {
  pointer-events: none;
  opacity: 0.7;
}

.wrapper-reset-card-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.btn-reset-card-modal {
  background: linear-gradient(to bottom, #5b13b7 0%, #8243df 100%);
  border: none;
  border-radius: 30px;
  padding: 12px 32px;
  font-size: 14px;
  color: #fff !important;
}
