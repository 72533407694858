@import "../../theme/base/variables";

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 26px 80px 58px;
  position: relative;
  max-width: 1440px;
  margin: 0 auto;

  &:before,
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 107px;
    transform: translateX(-50%);
    width: 129px;
    height: 129px;
    border-radius: 50%;
    background: linear-gradient(292.46deg, $purple -17.3%, $dark-purple 142.23%);
    opacity: 0.8;
    margin-left: 129px;
  }

  &:before {
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    width: 134px;
    height: 134px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
    left: 300px;
    top: -51px;
    margin: 0;
  }

  .back_btn {
    position: absolute;
    left: 80px;
    top: 26px;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    padding: 10px 33px 10px 25px;
    height: 42px;
    display: inline-flex;
    align-items: center;

    svg {
      width: 13px;
      margin-right: 14px;
    }
  }
}

.header_logo {
  width: 139px;
  height: 43px;
  display: block;
  position: relative;
}