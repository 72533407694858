@import "../../../../theme/base/variables";

.card {
  // height: 172px;
  padding: 15px 16px 0px 20px;
  background: $light-purple4;
  border-radius: 14px;
  border: 1px solid $purple;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 32px;
  }
  .card_title {
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 26px;
    .invalid {
      color: #e4597b;
      padding: 8px 16px 6px;
      background-color: #fff;
      font-size: 12px;
      line-height: 16px;
      position: relative;
      border-radius: 14px;
      margin-right: 6px;
      top: -7px;
    }
    .card_checkbox {
      width: 22px;
      height: 22px;
      border-radius: 50%;
      border: 1px solid $border-button-color;
    }
    .icon_default {
      font-size: 12px;
      padding: 8px 12px;
      color: $white;
      background: $dark-purple-3;
      border-radius: 22px;
    }
  }
  .card_number {
    font-size: 24px;
    color: $light-purple3;
    list-style: none;
    display: flex;
    margin-bottom: 24px;
    padding: 0;
    li {
      margin-right: 26px;
    }
  }
  .card_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: $light-purple3;
    margin-bottom: 20px;
  }
  .btn_edit {
    padding: 2px;
    color: #7330cf;
    background: #f7f4fc;
    font-size: 14px;
    border: none;
    margin-bottom: 16px;
    i {
      margin-right: 10px;
    }
  }
}
