@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.slider_holder {
  padding: 0 80px;

  @include media("<1400px") {
    padding: 0 32px;
  }
  @include media("<desktop") {
    padding: 0 8px;
  }
}

.service_slider {
  margin-top: -128px;
  margin-bottom: 66px;
  position: relative;
  z-index: 1;
}

.slide_box {
  min-height: 271px;
  background-position: center !important;
  background-repeat: no-repeat !important;
  background-size: 100% 100% !important;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  padding: 32px 24px;
  @include media("<desktop") {
    min-height: 220px;
  }
}

.slide_box_2 {
  align-items: flex-end;
}

.slide_box_3 {
  align-items: center;
  justify-content: center;
}

.loading_icon {
  color: #5b13b7 !important;
}

.text {
  color: $white;
  max-width: 260px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  min-height: 225px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    margin: 0 0 20px;
    font-weight: 400;

    @include media("<desktop") {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .text_title {
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 0px;
  }

  button {
    width: 125px;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding: 12px 16px !important;
  }
}

.wrapper_slide_box {
  border-radius: 20px !important;
  padding: 0;
  border: none;
  &:hover {
    cursor: pointer;
  }
}
