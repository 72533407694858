@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.border_bottom_notification {
  border-bottom: 1px solid #edeeef;
}
.wrapper_value_notification {
  display: flex;
  padding: 32px 0;
  align-items: center;
  border-top: 1px solid #edeeef;
  .icon_bg_NEW_BOOKING,
  .icon_bg_CANCELED_BOOKING,
  .icon_bg_experience,
  .icon_bg_RESCHEDULE_ON_BOOKING {
    @include media("<desktop") {
      padding: 16px 0;
    }
    .icon_bg_new_booking,
    .icon_bg_cancel_booking,
    .icon_bg_experience {
      background-color: transparent;
    }
    background-color: transparent;
  }
  .icon_bg_discount {
    background-color: #ffebda;
  }
  .icon_bg_rating_booking,
  .icon_bg_NO_SHOW,
  .icon_bg_UPCOMING_BOOKING {
    background-color: #dad0fb;
  }
  .icon_bg_complete_booking {
    background-color: #caf3da;
  }
  .icon_bg_change_card,
  .icon_bg_INVALID_CARD {
    background-color: #ffecf1;
  }
  .wrapper_img_notification {
    width: 68px;
    height: 68px;
    border-radius: 50%;
    margin-right: 40px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include media("<desktop") {
      margin-right: 30px;
      min-width: 68px;
    }
    img {
      width: 68px;
      height: 68px;
      border-radius: 50%;
      margin-right: 40px;
      border: 1px solid #edeeef;
    }
  }
  .wrapper_value_text {
    flex: 1;
    .text_bold_notification {
      font-weight: bold;
      color: #1f1e20;
    }
    .wrapper_value_title {
      .value_title {
        font-size: 20px;
        font-weight: 500;
        color: #7e849b;
        line-height: 28px;

        @include media("<desktop") {
          font-weight: normal;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .wrapper_value_btn {
      .value_btn {
        font-size: 18px;
        font-weight: 400;
        color: #5b13b7;
        line-height: 24px;

        @include media("<desktop") {
          font-size: 16px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
    .wrapper_create_notification {
      margin-top: 4px;
      .create_notification {
        font-size: 16px;
        font-weight: 500;
        color: $black;
      }
    }
  }
}

.booking_id_text {
  color: $purple;
  font-size: 16px;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}
