.wrapper-btn-show-booking {
  max-width: 92px;
  .show-less-btn-booking {
    transform: rotate(0deg);
  }
  .show-more-btn-booking {
    transform: rotate(180deg);
  }
  .MuiAccordionSummary-expandIcon {
    padding: 0px;
    svg {
      font-size: 16px;
    }
  }
}
