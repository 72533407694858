@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.container {
  position: relative;
  align-items: center;
  overflow: hidden;
  height: 520px;
  width: 818px;
  font-size: 16px/1;

  @include media("<tablet") {
    width: 100%;
    height: 100%;
  }

  @include media(">=tablet","<=desktop") {
    width: 100%;
    height: 100%;
  }
  .share_button {
    top: 0;
    left: 40px;
  }

  img {
    height: 520px;
    width: 818px;
    object-fit: cover;

    @include media("<desktop") {
      object-fit: container;
      width: 100%;
      height: 100% !important;
    }
  }

  .image_placeholder {
    display: flex !important;
    height: 520px;
    width: 818px !important;
    justify-content: center;
    align-items: center;
    background: $light-blue-4;
    @include media("<desktop") {
      width: 100% !important;
    }
  }
}

.action_buttons {
  position: absolute;
  top: 40px;
  padding: 0 40px;
  @include media("<desktop") {
    right: 60px;
    padding: 0 10px;
    top: 20px;
  }

  .share_button {
    padding: 10px 20px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px !important;
    background-color: rgba(255, 255, 255, 0.2) !important;
    border: none !important;
    color: $white !important;
    border-radius: 40px;
    position: relative;

    @include media("<desktop") {
      background-color: initial !important;
      padding: 10px 0px !important;
    }

    svg {
      width: 32px;
      margin-right: 12px;
    }

    &:hover {
      .modal_share_button {
        display: block;
      }
    }

    .modal_share_button {
      display: none;
      position: absolute;
      top: 40px;

      .modal_share_button_content {
        width: 200px !important;
        display: flex;
        flex-direction: column;
        padding: 12px;
        background-color: $white;
        margin-top: 4px;
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 14px;
        .modal_share_btn {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          background-color: transparent;
          border: none;
          height: 24px;
          padding: 0;
          margin: 4px 0 4px 12px;
          text-align: left;
          color: $black;

          &:hover {
            color: $purple;
          }
        }
      }
    }
  }
}

.action_buttons_2 {
  display: none;
}

.slider_buttons {
  position: absolute;
  top: 50%;
  display: flex;
  width: 100%;
  padding: 0 40px;
  justify-content: space-between;

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    width: 56px;
    background-color: rgba(255, 255, 255, 0.2);
    padding: 0;
    border: none;
    color: $white;

    svg {
      width: 14px;
    }
  }
}

.venueImages {
  >img {
    height:400px !important
  }
}