@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.search_panel {
  width: 320px;
  height: 48px;
  padding-left: 21px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  border-radius: 40px !important;
  background: $dark-purple-3;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border: 1px solid transparent;
  transition: all .3s ease-in-out;

  @include media('>=tablet','<=desktop') {
    width: 230px;
    margin-left: 6px;
  }

  @include media('<desktop') {
    margin-left: 0px;
    width: 100%;
    border-radius: 8px !important;
    background: none !important;
  }

  button.search_btn {
    border: none !important;
    padding: 0;
    height: auto;
    margin: 0;
    background: none !important;
    border-radius: 0;
  }
}

.paper {
  width: 490px;
  min-height: 194px;
  padding: 20px 20px 16px;
  color: $input-black;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  margin-top: 8px;
  font: 500 14px/20px $base-font-sans-serif;

  ul {
    border: none !important;
    box-shadow: none !important;
    padding: 16px 0 0 !important;

    li {
      margin: 0 8px 8px 0 !important;
      padding: 11px 20px 9px !important;
    }
  }
}

.paper_2 {
  width: 480px;
  color: $input-black;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  margin-top: 38px;
  font: 500 14px/20px $base-font-sans-serif;
  @include media('<desktop') {
    width: 100%;
    box-shadow: none !important;
    transform: translate3d(0px, 0px, 0px);
    position: absolute;
    top: -30px
  }

  ul {
    width: 100%;
    padding: 0px !important;
    @include media('<desktop') {
      border-radius: 0px !important;
      height: 100%;
      max-height: calc(100vh - 20px);
      box-shadow: none !important;
      border: none !important;
    }
    li {
      width: 100% !important;
      height: auto !important;
      margin: 0 !important;
      padding: 0 !important;
      border-radius: 0 !important;
      box-shadow: none !important;
      border: none !important;
      div {
        color: #000 !important;
        font-size: 16px !important;
        font-weight: 600;
      }
      ul {
        border: none !important;
        box-shadow: none !important;
        padding: 0 !important;
        border-radius: 0 !important;
        li {
          padding: 11px 20px 9px !important;
          color: #000 !important;
          font-size: 16px !important;
          position: relative;
          @include media('<desktop') {
            padding: 20px 20px !important;
            color: #7E849B !important;
            &:after {
              bottom: 0;
              content: " ";
              position: absolute;
              right: 0;
              height: 1px;
              background : rgb(231, 232, 233);
              width: 100%;
              left: 20;
            }
          }
         
          .popper_2 {
            font-weight: 400;
            .titleSearchForm {
              mark {
                background-color: transparent;
                font-weight: 700 !important;
                font-size: 17px !important;
              }
            }
          }
        }
      }
    }
  }
}

.paper_3 {
  width: 490px;
  height: 48px;
  color: #7e849b;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  margin-top: 8px;
  font: 500 14px/20px $base-font-sans-serif;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media('<desktop') {
    width: 100%;
    border-radius: 0px !important;
    box-shadow: none !important;
    transform: translate3d(0px, 0px, 0px);
  }
}

.popper {
  color: $purple !important;
  font: 500 12px/14px $base-font-sans-serif;
}

.popper_2 {
  font: 500 12px/14px $base-font-sans-serif;
  font-size: 16px;
  color: rgb(0, 0, 0) !important;
}

.search_btn {
  padding: 0 !important;
  color: $black !important;

  + div {
    width: 100%;
    margin: 0 0 0 10px;
    padding: 0 !important;

    > div {
      padding: 0 !important;
      border: none !important;
    }
  }

  svg {
    width: auto !important;
    margin: 0 !important;
    fill: $white;
    transition: fill .3s ease-in-out;
    @include media('<desktop') {
      fill: #7e849b !important;
    }
  }
}

.search_input {
  margin: 0;
  height: 48px !important;
  width: 100%;
  padding: 5px 11px !important;
  color: $white;
  border: none !important;

  label {
    color: $white;
    @include media('<desktop') {
      color: #7e849b;
    }
    font: 500 16px/20px $base-font-sans-serif;
    transform: translate(0, 15px);
    transition: opacity 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, color .3s ease-in-out;

    &[data-shrink="true"] {
      opacity: 0;
      color: $white;
      transform: translate(0, 15px);
    }
  }

  input[type="search"] {
    background: none;
    padding: 2px !important;
    height: 48px;
    border: none;
    color: $white;
    font: 500 16px/20px $base-font-sans-serif;
    transition: color .3s ease-in-out;
    @include media('<desktop') {
      color: #000 !important
    }
  }

  fieldset {
    border: none;
    height: 48px;
    top: 0;
  }
  
  button {
    display: none;
  }
}