@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.card_service {
  padding: 32px 0 0;
  border-top: 1px solid $border-button-color;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  @include media("<desktop") {
    padding: 10px 20px;
  }

  .service_info {
    max-width: 520px;
    padding-right: 12px;

    .title_box {
      margin-bottom: 16px;

      .title {
        font-size: 20px;
        color: #1f1e20;
        margin-right: 7px;
        @include media("<desktop") {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }

      .dot {
        font-size: 20px;
        color: #1f1e20;
      }

      .duration {
        font-size: 20px;
        color: #7e849b;
        margin-left: 7px;
        @include media("<desktop") {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    .description {
      margin-bottom: 20px;

      .btn_show_description {
        margin-top: 4px;
        max-width: 92px;
        padding: 0;

        p {
          color: $purple;
          font-weight: 600;
          font-size: 16px;
        }

        &:hover {
          cursor: pointer;
        }

        @include media("<desktop") {
          max-width: 86px;
          display: flex;
          justify-content: center;
          align-items: center;

          p {
            color: $black;
            font-weight: 400;
            font-size: 12px !important;
            line-height: 16px;
          }
        }
      }

      .title_2 {
        font-size: 18px !important;
        font-weight: 400;
        line-height: 24px;
        color: $gray !important;

        @include media("<desktop") {
          font-size: 12px !important;
          line-height: 16px;
        }
      }

      @include media("<desktop") {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .service_book {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: flex-end;

    button.btn_book {
      width: 99px;
      height: 44px;
      padding: 14px 32px;
      color: $white;
      background: $black;
      font-size: 14px;
      font-weight: 600;
      border: none;
      border-radius: 22px;
      margin-bottom: 24px;

      @include media("<desktop") {
        width: 90px;
        height: 40px;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
      }
    }

    .price_box {
      display: flex;
      align-items: baseline;

      .cost {
        font-size: 32px;
        line-height: 40px;
        color: $purple;

        @include media("<desktop") {
          font-weight: normal;
          font-size: 16px;
          line-height: 22px;
        }
      }

      .old_cost {
        font-size: 18px;
        line-height: 24px;
        font-weight: normal;
        opacity: 0.4;
        margin-right: 18px;
        position: relative;
        color: $purple;

        @include media("<desktop") {
          font-weight: normal;
          font-size: 12px;
          line-height: 16px;
        }

        &:after {
          content: "";
          position: absolute;
          left: -3px;
          color: $purple;
          right: -3px;
          top: 50%;
          transform: translateY(-50%) rotate(162deg);
          height: 1px;
          background: $purple;
          margin-top: -2px;
        }
      }
    }
  }
}
.tabPanel {
  > div {
    @include media("<desktop") {
      padding: 0px;
    }
  }
}
