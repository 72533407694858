@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.time {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $purple;
  padding: 20px 24px;
  margin: 0 -40px;
  border-bottom: 1px solid $border-button-color;
}

.row {
  padding: 20px 24px;
  margin: 0 -40px;
  @include media("<desktop") {
    margin: 0px;
  }

  + .row {
    border-top: 1px solid $border-button-color;
  }

  &:first-child {
    .cost {
      font-size: 20px;
      line-height: 24px;
    }
  }
}

.row_holder {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;

  + .row_holder {
    margin-top: 10px;
  }

  &.row_total {
    font-size: 18px;

    .cost {
      font-size: 20px;
      line-height: 28px;
    }
  }
}

.cost {
  color: $purple;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  @include media("<desktop") {
    font-weight: 500;
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

.user {
  color: $gray;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  .avatar {
    font-size: 14px;
    line-height: 20px;
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  button {
    margin: 0 !important;
    color: $purple !important;
    font-weight: normal !important;
  }
}

.btn_dialog {
  margin: 0 -40px;
  padding: 0 24px;

  button {
    width: 100%;
    height: 52px;
    font-weight: 600;
  }
}

.btn_add {
  color: $purple;
  background: $white;
  border: 1px solid $border-button-color;
  box-sizing: border-box;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  padding: 10px 20px;
}

.btn_box {
  text-align: center;
}