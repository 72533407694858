@import "../../../../theme/base/variables";
@import "../../../../theme/vendors//include-media";

div {
  .date_picker {
    margin: 0 !important;
    margin-left: 12px !important;
    border-radius: 22px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    padding: 0;
    width: 100px;

    @include media("<desktop") {
      background: #7F2FEA;
      width: 80px;
    }

    label {
      color: $purple;
      font-size: 14px;
      line-height: 20px;
      top: -19px;
      left: 15px;

      @include media("<desktop") {
        color: $white;
        font-size: 12px;
      }

      &[data-shrink="true"] {
        display: none;
      }

      + div {
        margin: 0;
      }
    }

    input {
      background: none;
      border: none;
      padding: 6px 16px;
      height: auto;
      width: 100%;
      color: $purple;
      border-radius: 0;
      @include media("<desktop") {
        color: $white;
        font-size: 12px;
      }
    }

    svg {
      display: none;
    }

    button {
      padding: 0;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border-radius: 0;
      width: 100%;
      box-shadow: none;

      &:hover {
        background: none;
      }

      &:after {
        content: '';
        border-top: 4px solid $purple;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
        right: 13px;
        top: 13px;

        @include media("<desktop") {
          border-top-color: $white;
        }
      }
    }
  }
  .date_picker_mobile {
    @include media("<desktop") {
      visibility: hidden
    }
  }
  .date_picker_selected {
    background-color:$purple;

    label  {
      color: $white;

      @include media("<desktop") {
        color: $purple;
      }
    }

    @include media("<desktop") {
      background-color: $white;
    }

    input {
      color: $white;

      @include media("<desktop") {
        color: $purple;
        font-size: 12px;
      }
    }
    button {
      &:after {
        border-top: 4px solid $white;
        @include media("<desktop") {
          border-top: 4px solid $purple;
        }
      }
    }
  }
}

.btn_box {
  order: 2;
  padding: 20px 24px 0;
}

.btn_apply {
  background: linear-gradient(323.63deg, #5B13B7 0%, #8243DF 100%);
  border-radius: 31px;
  font-size: 16px;
  line-height: 22px;
  color: $white;
  padding: 13px;
  display: block;
  margin: 0 0 7px;
  border: none;
  width: 100%;
  text-align: center;
  @include media("<desktop") {
    width: 300px;
    margin: auto;
  }
}

.btn_clear {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  padding: 13px;
  background: none;
  border: none;
  width: 100%;
  text-align: center;
}