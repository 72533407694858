@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";
.box {
  padding: 0 10px 21px;
  width: 33.333%;

  @include media("<tablet") {
    width: 100%;
    padding: 20px 0px;
  }

  @include media(">=tablet", "<desktop") {
    width: 50%;
    padding: 20px;
  }
}

.booking_box {
  cursor: pointer;
  border-radius: 14px;
  box-shadow: 0 4px 20px rgba(89, 89, 89, 0.1);

  @include media("<desktop") {
    position: relative;
    z-index: 1;
    background: #fff;
  }
}

.service_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: $black;
  padding: 0 20px 12px;

  time {
    color: $purple;
    font-weight: normal;
    margin-left: 10px;
  }
}

.total_box {
  color: $black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 20px;
  border-top: 1px solid #fafafa;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  .cost {
    color: $purple;
    font-size: 18px;
    line-height: 24px;
    margin-left: 10px;
  }

  .rightSide {
    display: flex;
    align-items: center;
    position: relative;
  }

  .invalid {
    font-size: 12px;
    line-height: 16px;
    background-color: #ffecf1;
    color: #e4597b;
    padding: 6px 12px 4px;
    border-radius: 4px;
    position: relative;
    top: -1px;
  }

  .warningWrap {
    position: absolute;
    left: -70px;
    top: 40px;
    width: 230px;
    z-index: 2;
    background-color: #fff;
    border-radius: 14px;
    border: 1px solid #edeeef;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;

    p {
      color: #7e849b;
      font-size: 12px;
      line-height: 16px;
    }

    .bold {
      font-weight: 700;
      color: #000;
    }

    button {
      height: 40px;
      padding: 10px;
      border-radius: 14px;
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
      border: none;
      color: #fff;
    }

    .triangle {
      position: absolute;
      right: 104px;
      top: -8px;
      width: 14px;
      height: 14px;
      z-index: 0;
      transform: rotate(45deg);
      background-color: #fff;
      border: 1px solid #edeeef;
      border-right: transparent;
      border-bottom: transparent;
    }
  }
}
