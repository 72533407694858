@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.selected_sort {
  color: $white;
  font-size: 14px;
  background: $purple;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 22px;
  padding: 6px 16px;

  @include media("<desktop") {
    background: $white;
    color: $purple;
    font-size: 12px;
    width: unset;
    text-align: center;
    white-space: nowrap;
  }
}

.sort_by {
  z-index: 1;
  position: relative;

  .btn {
    display: inline-flex;
    align-items: center;
    background: $white;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
    border-radius: 22px;
    padding: 6px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    color: $purple;
    border: none;
    white-space: nowrap;

    @include media("<desktop") {
      background: #7f2fea;
      color: $white;
      font-size: 12px;
    }

    &:after {
      content: "";
      border-top: 4px solid $purple;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      width: 0;
      height: 0;
      margin-left: 11px;
      z-index: 1;

      @include media("<desktop") {
        border-top-color: $white;
      }
    }

    &[aria-controls="menu-list-grow"] {
      color: $white;
      background: $purple;
      @include media("<desktop") {
        background: #ffffff;
        color: #8461f3;
      }
      span {
        @include media("<desktop") {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
        }
      }

      &:after {
        border-top: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-bottom: 4px solid $white;

        @include media("<desktop") {
          border-bottom-color: #8461f3;
        }
      }
    }
  }

  .btn_select {
    @include media("<desktop") {
      background: $white !important;
      color: $purple;

      &:after {
        border-top-color: $purple;
      }
    }
  }
}

.paper {
  width: 330px;
  background: $white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  margin-left: 112px;
  margin-top: 12px;

  @include media("<desktop") {
    width: 100%;
    margin: 0;
    height: 100%;
  }

  fieldset {
    width: 100%;

    li {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}
.btn_box {
  order: 2;
  padding: 20px 24px 0;
}

.btn_apply {
  background: linear-gradient(323.63deg, #5b13b7 0%, #8243df 100%);
  border-radius: 31px;
  font-size: 16px;
  line-height: 22px;
  color: $white;
  padding: 13px;
  display: block;
  margin: 0 0 7px;
  border: none;
  width: 100%;

  @include media("<desktop") {
    width: 300px;
    margin: auto;
  }
}

.btn_clear {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray;
  padding: 13px;
  background: none;
  border: none;
  width: 100%;
}

.menuItem {
  label {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;
    margin: 0px;
  }
}

.titleMobile {
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  padding-left: 16px;
  padding-top: 32px;
}
