@import "../../../../theme/base/variables";

button.drop_btn {
  width: 111px;
  padding: 14px 8px 14px 23px !important;

  &[aria-controls="menu-list-grow"] {
    svg {
      transform: rotate(180deg);
    }

    &:after {
      opacity: 1;
      visibility: visible;
    }
  }

  .text_btn {
    width: 54px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    letter-spacing: -0.5px;
    text-align: left;
  }

  svg {
    width: 24px;
    fill: $white;
    transition: fill .3s ease-in-out;
    flex-shrink: 0;
  }

  i {
    margin-right: 11px;
    margin-top: -2px;
  }
}

button.drop_btn_mobile {
  width: 100% !important;
  background: none !important;
  border: none !important;
  padding: 0 !important;
  margin-left: 0 !important;
  min-width: 40px !important;
}

button.drop_btn_user {
  width: 188px;

  .text_btn {
    width: 104px;
  }
}

.popper {
  padding-top: 8px;
}

.drop_holder {
  background: $white;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05) !important;
  border-radius: 14px !important;
  width: 188px;

  .MenuList {

    li {
      color: $purple;
      font: 16px/20px $base-font-sans-serif;
      padding: 10px 10px 10px 24px;

      &.gray_color {
        color: $gray;
      }
    }
  }
}

.notes {
  min-width: 17px;
  height: 17px;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  color: $white;
  text-align: center;
  padding: 1px 2px 0;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  margin-top: -2px;
  margin-left: 10px;
  background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
}