@import "../../../../theme/base/variables";
@import "../../../../theme/vendors/include-media";

.container {
  padding: 16px 0;
  max-width: 522px;
  @include media("<desktop") {
    padding: 16px 20px;
    max-width: 100%;
  }

  h1 {
    @include media("<desktop") {
      display: none;
    }
  }
}

.description {
  margin-bottom: 24px;
  white-space: pre-wrap;

  @include media("<desktop") {
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #1f1e20;
  }

  .title {
    font-size: 18px !important;
    font-weight: 400;
    line-height: 24px;
    color: $gray !important;

    @include media("<desktop") {
      font-size: 12px !important;
      line-height: 16px;
    }
  }
}

.tags {
  display: flex;
  flex-wrap: wrap;
  margin-right: -16px;
  margin-bottom: -12px;

  span {
    font-weight: 400;
    line-height: 16px;

    margin-right: 16px;
    margin-bottom: 12px;
    padding: 8px 16px;
    border: 1px solid $light-gray;
    border-radius: 22px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  }
}

.btn_show_description {
  color: $purple;
  font-weight: 600;
  font-size: 16px;
  margin-top: 4px;
  max-width: 92px;

  &:hover {
    cursor: pointer;
  }
}
