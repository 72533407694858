@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";
.container {
  max-width: 1440px;
  margin: 0 auto;
  padding: 0 80px 100px;
}

.inner_heading {
  position: relative;
  min-height: 291px;
  max-height: 327px;
  padding: 155px 0 68px;
  color: $white;
  text-align: center;
  z-index: 1;
  margin: 50px 0 60px;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    z-index: -2;
    transform: translateX(-50%);
    width: 100%;
    min-width: 1440px;
    height: 327px;
    clip-path: ellipse(93% 74% at 50% 26%);
    background: linear-gradient(323.63deg, $gradient-start 0%, $gradient-end 100%);
    background-size: 100% 327px;
    font-size: 18px;
    line-height: 24px;
  }

  .inner_text {
    max-width: 1440px;
    padding: 0 80px;
    margin: 0 auto;

    h1 {
      color: $white;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      margin: 0 0 12px;
      @include media("<tablet") {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px;
      }
    }

    span {
      margin: 0;
      @include media("<tablet") {
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
      }
    }

    span.text_2 {
      font-weight: 600;
    }
  }
}

.inner_heading_mobile {
  min-height: 80px;
  max-height: 80px;
  padding: 120px 0 42px;
  margin: 50px 0 20px;

  @include media('>=tablet','<desktop') {
    padding-bottom: 80px;
  }
}

.circle {
  position: absolute;
  right: 0;
  top: 0;
  background: linear-gradient(292.46deg, #5B13B7 -17.3%, #8243DF 142.23%);
  opacity: 0.8;
  transform: rotate(-180deg);
  border-radius: 50%;
  z-index: -1;

  &.big {
    top: -95px;
    right: 166px;
    width: 296px;
    height: 296px;
  }

  &.medium {
    top: -60px;
    left: 312px;
    right: auto;
    width: 224px;
    height: 224px;
  }

  &.normal {
    top: 20px;
    right: 349px;
    width: 144px;
    height: 144px;
  }

  &.normal_2 {
    top: 106px;
    right: 278px;
    width: 115px;
    height: 115px;
  }

  &.small {
    top: 120px;
    right: auto;
    left: 325px;
    width: 91px;
    height: 91px;
  }
}
