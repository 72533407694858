@import '../../theme/base/variables';
@import '../../theme/vendors/include-media';

section.container {
  padding-top: 100px;
  padding-bottom: 20px;
  @include media('<desktop') {
    min-height: 400px;
    position: relative;
    z-index: 12;
  }

  h1,
  h2 {
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    margin: 0 0 24px;
    @include media('<desktop') {
      margin: 0 0 8px;
    }
    span {
      color: $purple;
    }
  }
}

.section_boxes {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px 19px;

  @include media('<desktop') {
    margin: 0px;
  }

  .box {
    padding: 0 10px 21px;
    width: 33.333%;
    @include media('<desktop') {
      width: 100% !important;
    }
  }
}

.btnTab {
  width: 48%;
  min-width: 50%;
  border-radius: 8px !important;
  box-shadow: 0px 4px 4px #5f21b1;
}
.tabsRoot {
  width: 100%;
  & .MuiTabs-indicator {
    display: none;
  }
}
.tabPanel {
  width: 100%;
  > div {
    padding: 0px !important;
    @include media(">=tablet", "<desktop") {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.visual {
  position: absolute;
  width: 100%;
  height: 250px;
  z-index: 0;
  padding: 0px;
  margin-top: -22px;
  clip-path: ellipse(82% 61% at 50% 39%);
  background: linear-gradient(
    323.63deg,
    $gradient-start 0%,
    $gradient-end 100%
  );
  overflow: hidden;

  @include media('<desktop') {
    height: 250px;
  }

  &:before {
    content: '';
    width: 115px;
    height: 115px;
    border-radius: 50%;
    position: absolute;
    top: 140px;
    right: -5px;
    background: linear-gradient(323.63deg, $purple 0%, $dark-purple 100%);
    opacity: 0.8;
  }

  .visual_holder {
    max-width: 1114px;
    margin: 0 auto;
    min-height: 627px;
    display: flex;

    @include media('<desktop') {
      display: none;
    }
  }
}
