.marker-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  transform: translate(-50%, -50%);
  border: 2px solid #fff;
  border-radius: 50%;
  position: relative;
  background-color: #8461F3;
}

.marker-wrap .marker-card {
  position: absolute;
  z-index: 2;
  background-color: #fff;
  width: 224px;
  border-radius: 14px;
  top: 36px;
  left: 0px;
}

.marker-wrap .marker-card > div > div img {
  width: 100%;
  height: 123px;
  object-fit: cover;
}

.marker-wrap .marker-card > div > div:last-child {
  padding: 0 16px 16px;
}
