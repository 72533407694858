@import "../../theme/base/variables";
@import "../../theme/vendors/include-media";

.heading {
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    margin: 0;
  }
}

.check_block {
  display: block !important;
  margin-left: -10px;
  margin-right: -40px;
  margin-bottom: 24px;
  position: relative;

  @include media("<desktop") {
    margin: 0px;
  }
  &:after {
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 38px;
    background: linear-gradient(90deg, $white 0%, rgba($white, 0) 86.84%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
  }
}
