@import '../../../../theme/base/variables';
@import '../../../../theme/vendors/include-media';

.time {
  display: block;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $purple;
  padding: 20px 24px;
  margin: 0 -40px;
  border-bottom: 1px solid $border-button-color;

  @include media('<desktop') {
    margin: 0px;
    padding: 20px 20px;
  }
}

.rootSummary {
  @include media('<desktop') {
    border: 1px solid #fafafa;
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(234, 234, 234, 0.75);
    border-bottom-left-radius: 14px;
    border-bottom-right-radius: 14px;
  }
}
