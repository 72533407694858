@import "../../theme/base/variables";

.not_specified {
  display: none !important;
}

.menu {
  ul {
    max-width: 424px !important;
    padding: 0 8px;
    li.MenuItem {
      color: $gray;
    }
  }
}

.ListSubheader {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: $gray !important;
  display: block;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0 0 20px;
  background: $white !important;

  span {
    position: relative;
    padding: 0 16px;

    &:before,
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      right: 100%;
      left: -9999px;
      height: 1px;
      background: $border-button-color;
    }

    &:after {
      left: 100%;
      right: -9999px;
    }
  }
}

li.MenuItem {
  background: $white;
  box-shadow: 0 4px 20px rgba(234, 234, 234, 0.75);
  border-radius: 8px;
  font-size: 12px;
  line-height: 16px;
  color: $gray;
  padding: 12px 20px;
  display: inline-block;
  vertical-align: top;
  margin: 0 8px 16px 8px;
  width: 86px;
}

li.disabled {
  display: none;
}

.wrapperBtnListSubheader {
  width: 100%;
  padding: 0;
  border: none;
  background: transparent;
}

.wrapperTimeSelect {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 16px;
}
